import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Jumbotron,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import { config } from "./config";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

class CreateRoleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  save() {
    const table = this.props.table;
    fetch(`${config.apiBase}/access/roles?name=${this.state.name}`, {
      method: "PUT",
      body: '{"roles":[],"is_leadership":false,"gice_group":null}',
      headers: {
        "content-type": "application/json",
      },
    })
      .then(handleErrors)
      .then(() => table.refreshData())
      .then(() => {
        table.setState({
          hasInflight: false,
        });
        this.toggle();
      })
      .catch((error) => {
        table.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  activate(row) {
    this.setState({
      modal: true,
    });
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
        >
          <ModalHeader toggle={() => this.toggle()}>New Role</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="inputName">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="inputName"
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class CreateTaskTypeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  save() {
    const table = this.props.table;
    const data = {
      notes: this.state.notes,
      roles: [],
      is_leadership: false,
      gice_group: null,
    };
    fetch(`${config.apiBase}/access/task_types?name=${this.state.name}`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(handleErrors)
      .then(() => table.refreshData())
      .then(() => {
        table.setState({
          hasInflight: false,
        });
        this.toggle();
      })
      .catch((error) => {
        table.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  activate(row) {
    this.setState({
      modal: true,
    });
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
        >
          <ModalHeader toggle={() => this.toggle()}>New Task Type</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="inputName">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="inputName"
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="inputName">Notes</Label>
                <Input
                  type="textarea"
                  name="notes"
                  id="inputNotes"
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const createRole = React.createRef();

class AccessControlTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      options: [],
      isLoading: true,
      didError: false,
      isCellDropdown: !!props.dropdownOptions,
    };
  }
  formatEnumAsTitle(enumString) {
    const reserved_list = ["id"];
    return (
      enumString &&
      enumString
        .toLowerCase()
        .split("_")
        .reduce(function (c, v) {
          c.push(
            reserved_list.indexOf(v) !== -1
              ? v.toUpperCase()
              : v.substring(0, 1).toUpperCase() + v.substring(1)
          );
          return c;
        }, [])
        .join(" ")
    );
  }
  giceGroupFormatter(data, row) {
    const inputChange = (e) => {
      const { data } = this.state;
      for (const drow of data) {
        if (drow.header === row.header) {
          drow.gice_group = e.target.value;
        }
      }
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        data,
      });
    };
    return (
      <Form>
        <FormGroup>
          <Input
            type="text"
            name="gice"
            onChange={inputChange}
            value={row.gice_group}
          />
        </FormGroup>
      </Form>
    );
  }
  dropdownFormatter(_ignore) {
    return (data, row, ri, ed) => {
      const editFn = (e) => {
        const { data } = this.state;
        for (const drow of data) {
          if (drow.header === row.header) {
            drow[ed] = e.target.value;
          }
        }
        this.setState({
          data,
        });
      };
      return (
        <Form>
          <FormGroup>
            <Input type="select" name="select" value={data} onChange={editFn}>
              {this.state.options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      );
    };
  }
  checkboxFormatter(editable) {
    return (data, row, ri, ed) => {
      const editFn = (e) => {
        const { data } = this.state;
        for (const drow of data) {
          if (drow.header === row.header) {
            drow[ed] = e.target.checked;
          }
        }
        this.setState({
          data,
        });
      };
      return (
        <Form>
          <FormGroup check>
            <Input
              type="checkbox"
              checked={data}
              onChange={editFn}
              disabled={!editable}
            />
          </FormGroup>
        </Form>
      );
    };
  }
  refreshData() {
    return fetch(`${config.apiBase}/access/${this.props.apiUrl}`)
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const { data, columns, options } = this.filterData(json);
        this.setState({
          data,
          columns,
          options,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  componentDidMount() {
    this.refreshData();
  }
  deleteRow(row) {
    this.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/access/${this.props.apiUrl}?name=${row}`, {
      method: "DELETE",
    })
      .then(handleErrors)
      .then((response) => {
        return this.refreshData();
      })
      .then(() => {
        this.setState({
          hasInflight: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  saveRow(row) {
    const { header, gice_group, is_leadership, ...newContent } = row;
    const request = {
      roles: this.state.isCellDropdown
        ? newContent
        : Object.entries(newContent).reduce((c, v) => {
            if (v[1]) c.push(v[0]);
            return c;
          }, []),
      is_leadership: !!row.is_leadership,
      gice_group: Number(gice_group) || null,
    };
    this.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/access/${this.props.apiUrl}?name=${row.header}`, {
      method: "PUT",
      body: JSON.stringify(request),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(handleErrors)
      .then(() => this.refreshData())
      .then(() => {
        this.setState({
          hasInflight: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  actionsFormatter(data, row) {
    let delBtn = "";
    if (this.props.deletable) {
      delBtn = (
        <Button
          color={"danger"}
          size={"sm"}
          onClick={() => this.deleteRow(row.header)}
        >
          Delete
        </Button>
      );
    }
    return (
      <div>
        <Button color={"primary"} size={"sm"} onClick={() => this.saveRow(row)}>
          Save
        </Button>
        {delBtn}
      </div>
    );
  }
  filterData(json) {
    const {
      colItem,
      rowItem,
      cellItem,
      headerItem,
      headerHuman,
      extraColumns,
      dropdownOptions,
    } = this.props;
    const options = json[dropdownOptions]
      ? json[dropdownOptions].map((option) => {
          return { value: option, label: this.formatEnumAsTitle(option) };
        })
      : [];
    const columns = [
      {
        text: headerHuman,
        dataField: "header",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
          return { width: "200px" };
        },
      },
    ];
    if (extraColumns) {
      columns.push({
        text: "GICE Group",
        dataField: "gice_group_fake",
        dummyField: true,
        formatter: (...args) => this.giceGroupFormatter(...args),
      });
    }
    const cellFormatter = this.state.isCellDropdown
      ? "dropdownFormatter"
      : "checkboxFormatter";
    for (const col of json[colItem]) {
      columns.push({
        text: col.name,
        dataField: col.name,
        formatExtraData: col.name,
        formatter: (...args) =>
          this[cellFormatter](col.gice_group == null)(...args), // eslint-disable-line eqeqeq
      });
    }
    columns.push({
      text: "",
      dummyField: true,
      dataField: "actions",
      formatter: (...args) => this.actionsFormatter(...args),
    });
    const data = [];
    for (const row of json[rowItem]) {
      const rowRepr = {
        header: row[headerItem],
        gice_group: row.gice_group,
        is_leadership: row.is_leadership,
      };
      if (this.state.isCellDropdown) {
        for (const item of json[colItem]) {
          rowRepr[item.name] = options && options[0].value;
        }
        for (const cell of row[cellItem]) {
          rowRepr[cell.role.name] = cell.access_level;
        }
      } else {
        for (const item of json[colItem]) {
          rowRepr[item.name] = false;
        }
        for (const cell of row[colItem]) {
          rowRepr[cell.name] = true;
        }
      }
      data.push(rowRepr);
    }
    return { columns, data, options };
  }

  renderTable() {
    const { columns, data, isLoading, hasInflight } = this.state;
    let createBtn = "";
    let spinner = "";
    if (hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    if (this.props.deletable) {
      createBtn = (
        <Button
          color={"primary"}
          className="float-right"
          onClick={() => createRole.current.activate()}
        >
          Add
        </Button>
      );
    }
    let spp = localStorage.getItem("sizePerPage");
    if (spp === null) {
      spp = 10;
    } else {
      spp = Number(spp);
    }
    function onSPPC(spp, page) {
      localStorage.setItem("sizePerPage", spp);
    }
    const pagination = paginationFactory({
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "All", value: 9007199254740991 },
      ],
      showTotal: true,
      sizePerPage: spp,
      onSizePerPageChange: onSPPC,
    });
    const AddModalComponent = this.props.addModalComponent || "CreateRoleModal";
    const table = (
      <Container fluid>
        <Row>
          <Col>
            <h1>{this.props.headerHuman}s</h1>
          </Col>
          <Col>
            {createBtn}
            {spinner}
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              keyField={"header"}
              bootstrap4
              striped
              hover
              condensed
              data={JSON.parse(JSON.stringify(data))}
              columns={columns}
              filter={filterFactory()}
              pagination={pagination}
              classes={["overflow-table"]}
              wrapperClasses={["overflow-table-wrapper"]}
              fluid
            />
          </Col>
        </Row>
        <AddModalComponent ref={createRole} table={this} />
      </Container>
    );
    if (isLoading) {
      return [];
    } else {
      return table;
    }
  }
  render() {
    const { didError, isLoading } = this.state;
    const errorOutput = (
      <Jumbotron fluid={this.props.fluid} className="alert alert-danger">
        <Container fluid={this.props.fluid}>Error fetching content</Container>
      </Jumbotron>
    );

    let content;
    if (didError) {
      content = errorOutput;
    } else {
      content = this.renderTable();
    }

    return (
      <LoadingOverlay active={isLoading} fadeSpeed={500}>
        <Container fluid>{content}</Container>
      </LoadingOverlay>
    );
  }
}

function UserTable() {
  return (
    <AccessControlTable
      colItem={"roles"}
      rowItem={"users"}
      headerItem={"username"}
      headerHuman={"User"}
      apiUrl={"users"}
    />
  );
}
function RoleTable() {
  return (
    <AccessControlTable
      colItem={"permissions"}
      rowItem={"roles"}
      headerItem={"name"}
      headerHuman={"Role"}
      extraColumns={true}
      apiUrl={"roles"}
      addModalComponent={CreateRoleModal}
      deletable={true}
    />
  );
}
function TaskTypeTable() {
  return (
    <AccessControlTable
      colItem={"roles"}
      rowItem={"task_types"}
      cellItem={"task_type_access"}
      headerItem={"name"}
      headerHuman={"Task Type"}
      extraColumns={false}
      dropdownOptions={"task_type_access_levels"}
      apiUrl={"task_types"}
      addModalComponent={CreateTaskTypeModal}
      deletable={true}
      fluid={true}
    />
  );
}

export { RoleTable, TaskTypeTable, UserTable };
