import React, { Component } from "react";
import { Container } from "reactstrap";

import TimerForm from "./TimerForm";

class AddTimer extends Component {
  render() {
    return (
      <Container>
        <h1>Add Timer</h1>
        <TimerForm />
      </Container>
    );
  }
}

export default AddTimer;
