import React, { Component } from "react";
import { Col, Row, Table } from "reactstrap";

import { config } from "./config";
import { dateFormatter } from "./sharedFormatters";

const ChangedRegex = /Changed (.*) from (.*) to (.*)/;

class TimerHistoryLineItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property: this.makePropertyNice(),
    };
  }

  makePropertyNice() {
    let { property } = this.props;
    property = property.split("_");
    const correctedWords = property.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    return correctedWords.join(" ");
  }

  render() {
    const { property } = this.state;
    const { oldValue, newValue } = this.props;

    return (
      <div>
        Changed <code>{property}</code> from <code>{oldValue}</code> to{" "}
        <code>{newValue}</code>
      </div>
    );
  }
}

class TimerHistoryItem extends Component {
  constructor(props) {
    super(props);

    this.state = this.parseRegex();
  }

  parseRegex() {
    const splitChanges = this.props.item.description.split("\n");
    const matches = splitChanges.reduce((arr, line) => {
      const matches = ChangedRegex.exec(line);
      if (matches) {
        arr.push({
          property: matches[1],
          oldValue: matches[2],
          newValue: matches[3],
        });
      }
      return arr;
    }, []);

    return { matches: matches };
  }

  render() {
    const { matches } = this.state;

    let description = matches.reduce((arr, change) => {
      if (change) {
        arr.push(
          <TimerHistoryLineItem
            property={change.property}
            oldValue={change.oldValue}
            newValue={change.newValue}
            key={arr.length}
          />
        );
      }
      return arr;
    }, []);

    if (matches.length <= 1 && matches[0] === undefined) {
      description = this.props.item.description;
    }

    return (
      <tr>
        <td className="linebreak-text">{description}</td>
        {/* TODO: It'd be nice to make this a link to gice */}
        <td>{this.props.item.user.display_name}</td>
        <td>{dateFormatter(new Date(this.props.item.created_at))}</td>
      </tr>
    );
  }
}

class TimerHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      history_items: [],
    };
  }

  componentDidMount() {
    fetch(`${config.apiBase}/timers/${this.props.timer_id}/history`)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          history_items: response,
        });
      });
  }

  render() {
    const rows = this.state.history_items.map((history, index) => {
      return <TimerHistoryItem key={index} item={history} />;
    });

    return (
      <Row>
        <Col>
          <h1>Timer History</h1>
          <Table>
            <thead>
              <tr>
                <th>Description</th>
                <th>User</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Col>
      </Row>
    );
  }
}

export default TimerHistory;
