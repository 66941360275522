import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Jumbotron,
  Row,
} from "reactstrap";

import { config } from "./config";

function handleErrors(response) {
  if (response.status === 403) {
    throw Error("forbidden");
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}
class AsyncTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true,
      didError: false,
      forbidden: false,
      currentUrl: null,
    };
  }
  reloadData() {
    if (this.props.requestUrl === this.state.currentUrl) {
      return;
    }
    this.setState({
      currentUrl: this.props.requestUrl,
      isLoading: true,
    });
    const rowFilter =
      this.props.rowFilter === undefined ? (a) => a : this.props.rowFilter;
    fetch(`${config.apiBase}/${this.props.requestUrl}`)
      .then(handleErrors)
      .then((response) => response.json())
      .then(rowFilter)
      .then((data) => {
        if (!this.props.testData) {
          this.setState({
            data: data,
            isLoading: false,
          });
        } else {
          this.testData();
        }
      })
      .catch((error) => {
        if (error.message === "forbidden") {
          this.setState({
            isLoading: false,
            forbidden: true,
          });
        } else {
          this.setState({
            isLoading: false,
            didError: true,
          });
          console.log("Error: ", error);
        }
      });
  }

  testData() {
    /*
      To use static test data for testing layouts follow the below steps
      
      1. Add the prop  testData={true}   to the AsyncTable in the component
      2. Add a case statement below for the component requestUrl
      3. Add a JSON file that matches the data model for your component in the static test data folder 
    */

    switch (this.props.requestUrl) {
      case "stargates":
        import("./static-test-data/stargates.json")
          .then((data) => {
            this.setState({ data: data.default, isLoading: false });
          })
          .catch((error) => {
            console.error("Error importing static testdata:", error);
          });
        break;
      default:
        this.setState({ data: [], isLoading: false });
    }
  }

  renderTable() {
    this.reloadData();
    const { data } = this.state;
    const sortDirection =
      this.props.sortDirection === undefined ? "asc" : this.props.sortDirection;
    const auxFilter =
      this.props.auxFilter === undefined ? (a) => a : this.props.auxFilter;
    let spp = localStorage.getItem("sizePerPage");
    if (spp === null) {
      spp = 10;
    } else {
      spp = Number(spp);
    }
    function onSPPC(spp, page) {
      localStorage.setItem("sizePerPage", spp);
    }
    function sizePerPageRenderer({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) {
      const items = [];
      currSizePerPage = Number(currSizePerPage);
      for (const option of options) {
        items.push(
          <Button
            key={option.text}
            onClick={() => onSizePerPageChange(option.page)}
            color="success"
            active={currSizePerPage === option.page}
          >
            {option.text}
          </Button>
        );
      }
      return <ButtonGroup>{items}</ButtonGroup>;
    }
    const pagination = paginationFactory({
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "All", value: 9007199254740991 },
      ],
      showTotal: true,
      sizePerPage: spp,
      onSizePerPageChange: onSPPC,
      sizePerPageRenderer,
    });
    return (
      <Row>
        <Col>
          <BootstrapTable
            keyField={this.props.keyField}
            bootstrap4
            striped
            hover
            condensed
            data={auxFilter(data)}
            columns={this.props.columnDefinition}
            defaultSorted={[
              {
                dataField: this.props.sortKey,
                order: sortDirection,
              },
            ]}
            filter={filterFactory()}
            pagination={pagination}
          />
        </Col>
      </Row>
    );
  }

  render() {
    const { didError, isLoading, forbidden } = this.state;

    let content;
    if (didError || forbidden) {
      let errMsg = "";
      if (forbidden) {
        errMsg = "You don't have the permissions to visit this page";
      } else if (didError) {
        errMsg = "Error fetching content.";
      }
      content = (
        <Jumbotron fluid={this.props.fluid} className="alert alert-danger">
          <Container fluid={this.props.fluid}>
            {errMsg} <a href="/api/login">Click here to log back in</a>
          </Container>
        </Jumbotron>
      );
    } else {
      content = this.renderTable();
    }

    return (
      <LoadingOverlay active={isLoading} fadeSpeed={500}>
        <Container fluid={this.props.fluid}>{content}</Container>
      </LoadingOverlay>
    );
  }
}

export default AsyncTable;
