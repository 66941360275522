import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row } from "reactstrap";

import AsyncTable from "./AsyncTable.js";

function parseDates(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].fuel_expires_at !== null) {
      data[i].fuel_expires_dt = new Date(data[i].fuel_expires_at);
    } else {
      data[i].fuel_expires_dt = null;
    }
  }
  return data;
}

class Pos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
    };
  }
  render() {
    const columns = [
      {
        text: "Moon",
        dataField: "moon_id",
        sort: true,
        formatter: (d, row) =>
          row.moon_id !== null
            ? row.moon.item_name
            : row.solar_system.solar_system_name,
        filterValue: (d, row) =>
          row.moon_id !== null
            ? row.moon.item_name
            : row.solar_system.solar_system_name,
        filter: textFilter(),
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "120px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "90px",
        },
      },
      {
        text: "Fuel Expires",
        dataField: "fuel_expires_dt",
        sort: true,
        formatter: (x, row) =>
          row.state === "ONLINE" ? x.toLocaleString() : row.state,
        headerStyle: {
          width: "130px",
        },
      },
      {
        text: "Strontium",
        dataField: "stront",
        sort: true,
        headerStyle: {
          width: "130px",
        },
      },
    ];
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>POS</h1>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"pos"}
                rowFilter={parseDates}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"fuel_expires_dt"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Pos;
