import update from "immutability-helper";
import React, { Component } from "react";
import Select from "react-select";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { config } from "./config";
import MenuList from "./MenuList";
import { dateFormatter } from "./sharedFormatters";

const rentalStates = [
  { value: "NOT_RENTED", label: "Not Rented" },
  { value: "ALTCORP", label: "AltCorp" },
  { value: "MEMBERCORP", label: "Member Corp" },
  { value: "ALLY", label: "Ally Corp" },
  { value: "PUBLIC", label: "Public" },
];

class CitadelEditModal extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  save() {
    this.parent.setState({
      hasInflight: true,
    });
    let tidPromise;
    if (this.state.tenant !== "") {
      tidPromise = fetch("https://esi.evetech.net/latest/universe/ids/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([this.state.tenant]),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((res) => {
          if ("corporations" in res) {
            return res.corporations[0].id;
          } else {
            throw new Error("Tenant Not Found");
          }
        });
    } else {
      tidPromise = Promise.resolve(null);
    }
    tidPromise
      .then((tenant_id) => {
        let updateObj = {
          hidden: this.state.hidden,
          rental_state: this.state.rentalState.value,
          tenant_id: tenant_id,
          notes: this.state.notes,
        };
        return fetch(
          `${config.apiBase}/citadels/${this.state.item_id}/set_meta`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateObj),
          }
        ).then((r) => [r, updateObj]);
      })
      .then(([resp, updateObj]) => {
        if (resp.ok) {
          this.parent.setState({
            dataOverride: update(this.parent.getDS(), {
              [this.state.index]: {
                gsf_data: {
                  $merge: {
                    ...updateObj,
                    tenant: {
                      name: this.state.tenant,
                    },
                    // TODO Might be nicer if this was properly refreshed from the server
                    last_updated_hidden_by: null,
                    last_updated_hidden_at: null,
                  },
                },
              },
            }),
          });
          this.toggle();
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert(err);
      })
      .finally((_) => {
        this.parent.setState({
          hasInflight: false,
        });
      });
  }
  activate(row) {
    let rentalState = rentalStates[0];
    for (const rs of rentalStates) {
      if (rs.value === row.gsf_data.rental_state) {
        rentalState = rs;
      }
    }
    this.setState({
      modal: true,
      rentalState: rentalState,
      index: row.ix,
      tenant: row.gsf_data.tenant === null ? "" : row.gsf_data.tenant.name,
      citname: row.structure_name,
      item_id: row.item_id,
      hidden: row.gsf_data.hidden,
      hidden_at:
        row.gsf_data.last_updated_hidden_at == null
          ? ""
          : dateFormatter(new Date(row.gsf_data.last_updated_hidden_at)),
      hidden_by:
        row.gsf_data.last_updated_hidden_by === null
          ? ""
          : row.gsf_data.last_updated_hidden_by.display_name,
      notes: row.gsf_data.notes,
    });
  }
  handleSelector(name, val) {
    this.setState({
      [name]: val,
    });
  }

  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const textareaStyle = {
      width: "100%",
    };
    const mutedFormTestStyle = {
      marginTop: "2px",
      marginBottom: "10px",
    };
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
        >
          <ModalHeader toggle={() => this.toggle()}>
            {this.state.citname}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="hidden"
                    id="inputHidden"
                    checked={this.state.hidden}
                    onChange={(e) => this.handleInputChange(e)}
                  />{" "}
                  Hidden
                </Label>
                <FormText
                  className="text-muted"
                  style={mutedFormTestStyle}
                  hidden={!this.state.hidden_by || !this.state.hidden}
                >
                  This was hidden by {this.state.hidden_by} on&nbsp;
                  {this.state.hidden_at}
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for="inputRentalState">Rental State</Label>
                <Select
                  components={{ MenuList }}
                  id="inputRentalState"
                  options={rentalStates}
                  onChange={(val) => this.handleSelector("rentalState", val)}
                  value={this.state.rentalState}
                />
              </FormGroup>
              <FormGroup>
                <Label for="inputTenant">Tenant</Label>
                <Input
                  type="text"
                  name="tenant"
                  id="inputTenant"
                  value={this.state.tenant}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="inputNotes">
                  Notes
                  <Input
                    type="textarea"
                    id="inputNotes"
                    name="notes"
                    style={textareaStyle}
                    rows="4"
                    cols="1000"
                    value={this.state.notes}
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default CitadelEditModal;
