import update from "immutability-helper";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import CitadelEditModal from "./CitadelEdit";
import { config } from "./config";
import { Countdown } from "./GlobalTime";

function expiryFormatter(cell) {
  if (cell !== null) {
    return <Countdown date={cell} />;
  }
  return "Low Power";
}

function parseDates(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].fuel_expires !== null) {
      data[i].fuel_expires_dt = new Date(data[i].fuel_expires);
    } else {
      data[i].fuel_expires_dt = null;
    }
    data[i].ix = i;
  }
  return data;
}

const citEdit = React.createRef();

function onEditCitadel(row) {
  return function (evt) {
    evt.preventDefault();
    citEdit.current.activate(row);
  };
}

function claimTask(self, row) {
  return function (evt) {
    self.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/tasks/cit_fuel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.item_id,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          self.setState({
            dataOverride: update(self.getDS(), {
              [row.ix]: {
                tasked_to: {
                  $set: "You",
                },
              },
            }),
          });
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally((_) => {
        self.setState({
          hasInflight: false,
        });
      });
  };
}

function actionsFormatter(self, cell, row) {
  if (row.tasked_to !== null) {
    return `Assigned to: ${row.tasked_to}`;
  }
  if (row.fuel_expires_dt !== null) {
    let daysLeft = Math.trunc(
      (row.fuel_expires_dt - new Date()) / 1000 / 60 / 60 / 24
    );
    if (daysLeft >= 7) {
      return "";
    }
  }
  return (
    <div>
      <Button color={"primary"} size={"sm"} onClick={claimTask(self, row)}>
        Claim Task
      </Button>
    </div>
  );
}

function nameFormatter(cell, row, ri) {
  return (
    <a href="#" onClick={onEditCitadel(row)}>
      {cell}
    </a>
  );
}

function serviceFormatter(cell) {
  let svc = "";
  let seenResearch = false;
  for (const sv of cell) {
    if (!sv.online) {
      continue;
    }
    if (
      sv.name === "Blueprint Copying" ||
      sv.name === "Material Efficiency Research" ||
      sv.name === "Time Efficiency Research"
    ) {
      if (!seenResearch) {
        seenResearch = true;
        if (svc !== "") {
          svc += ",\n";
        }
        svc += "Research\n";
      }
    } else {
      if (svc !== "") {
        svc += ",\n";
      }
      svc += sv.name;
    }
  }
  return svc;
}

class Citadels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
    };
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    const spacerStyle = {
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "20px",
    };
    const actFmt = (cell, row) => actionsFormatter(this, cell, row);
    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        formatter: nameFormatter,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "80px",
        },
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Fuel Expires",
        dataField: "fuel_expires_dt",
        sort: true,
        formatter: expiryFormatter,
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "Services",
        dataField: "services",
        sort: true,
        formatter: serviceFormatter,
      },
      {
        text: "",
        dataField: "item_id",
        formatter: actFmt,
        headerStyle: {
          width: "120px",
        },
        style: {
          textAlign: "center",
        },
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Citadel Fuel</h1>
            </Col>
            <Col>
              <Form className="float-right">
                <FormGroup check style={spacerStyle}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="showHidden"
                      id="inputShowHiddenCits"
                      checked={this.state.showHidden}
                      onChange={(e) => this.handleInputChange(e)}
                    />{" "}
                    Show Hidden Citadels
                  </Label>
                </FormGroup>
              </Form>
              {spinner}
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"citadels"}
                rowFilter={parseDates}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"fuel_expires_dt"}
                auxFilter={(a) => this.auxFilter(a)}
                fluid
              />
            </Col>
          </Row>
        </Container>
        <CitadelEditModal ref={citEdit} parent={this} />
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
  auxFilter(data) {
    if (this.state.dataOverride) {
      data = this.state.dataOverride;
    } else {
      this.dataStash = data;
    }
    if (!this.state.showHidden) {
      data = data.filter((a) => {
        if (a.gsf_data.hidden) {
          return false;
        }
        if (a.unanchors_at !== null) {
          const unanch_date = new Date(Date.parse(a.unanchors_at));
          if (unanch_date > new Date(Date.parse(a.fuel_expires))) {
            return false;
          }
        }
        if (a.last_fueled_at === null) {
          return true;
        }
        return new Date(Date.parse(a.last_fueled_at) + 14400000) < new Date();
      });
    }
    return data;
  }
}

export default Citadels;
