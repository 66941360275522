import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";

import TimerForm from "./TimerForm";

class EditTimer extends Component {
  render() {
    return (
      <Container>
        <h1>Edit Timer</h1>
        <div className="alert alert-danger">
          <p className="lead">Leave fields blank if they do not change!</p>
        </div>
        <TimerForm timer={this.props.activeTimer} />
      </Container>
    );
  }
}

export default connect(({ activeTimer }) => ({ activeTimer }))(EditTimer);
