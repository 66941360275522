import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";

import AsyncTable from "./AsyncTable.js";
import { countdownFormatter, dateFormatter } from "./sharedFormatters";

function parseDates(data) {
  data.map((entry) => {
    entry.unanchors_at_dt = new Date(entry.unanchors_at);
    return entry;
  });

  return data;
}

class Unanchoring extends Component {
  render() {
    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "80px",
        },
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Unanchoring in",
        dataField: "unanchors_at_dt",
        sort: true,
        formatter: countdownFormatter,
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "Unanchoring at",
        dataField: "unanchors_at",
        formatter: (x) => dateFormatter(new Date(x)),
      },
    ];

    return (
      <AsyncTable
        requestUrl={"citadels/unanchoring"}
        rowFilter={parseDates}
        columnDefinition={columns}
        keyField={"item_id"}
        sortKey={"unanchors_at"}
        fluid
      />
    );
  }
}

export default Unanchoring;
