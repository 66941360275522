import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Button, Col, Container, Row, Spinner } from "reactstrap";

import { PermissionRequired, Permissions } from "./Access";
import AsyncTable from "./AsyncTable.js";
import { config } from "./config";
import {
  countdownFormatter,
  dateFormatter,
  dotlanLinkFormatter,
} from "./sharedFormatters";

function parseDates(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].timer_expires = new Date(data[i].timer_expires);
    data[i].timer_countdown = data[i].timer_expires;
  }
  return data;
}

function detailsFormatter(self, row) {
  function onClick(isHostile) {
    const url = `${config.apiBase}/timers/fozziesov/${row.id}/promote?is_hostile=${isHostile}`;
    self.setState({
      hasInflight: true,
    });
    fetch(url, {
      method: "POST",
      body: "{}",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        alert("Timer added");
      })
      .catch((error) => {
        if (error.status === 403) {
          alert("You don't have the permission to do that");
        } else {
          alert("Something went wrong");
        }
      })
      .finally((_) => {
        self.setState({
          hasInflight: false,
        });
      });
  }
  return (
    <PermissionRequired role={Permissions.PROMOTE_SOV_TIMER}>
      <Button
        onClick={() => onClick(false)}
        className="btn-info btn-sm button-spread"
      >
        To Main Board (Blue)
      </Button>
      <Button
        onClick={() => onClick(true)}
        className="btn-info btn-sm button-spread"
      >
        To Main Board (Red)
      </Button>
    </PermissionRequired>
  );
}

class FozzieSov extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const actFmt = (_, row) => detailsFormatter(this, row);
    const columns = [
      {
        text: "System",
        dataField: "solar_system",
        sort: true,
        formatter: dotlanLinkFormatter,
        filter: textFilter(),
        filterValue: (v) => v.solar_system_name,
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Type",
        dataField: "structure_type.type_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Owner Ticker",
        dataField: "owning_alliance_ticker",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
          return { maxWidth: "100x", width: "100px" };
        },
      },
      {
        text: "Expires",
        dataField: "timer_expires",
        formatter: dateFormatter,
        sort: true,
      },
      {
        text: "Countdown",
        dataField: "timer_countdown",
        sort: true,
        formatter: countdownFormatter,
      },
      {
        text: "Actions",
        isDummyField: true,
        formatter: actFmt,
        dataField: "__actions__",
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>FozzieSov Timers</h1>
            </Col>
            <Col>{spinner}</Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"timers/fozziesov"}
                rowFilter={parseDates}
                columnDefinition={columns}
                keyField={"id"}
                sortKey={"timer_expires"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FozzieSov;
