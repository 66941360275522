import update from "immutability-helper";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { config } from "./config";

function addIndices(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].ix = i;
  }
  return data;
}

class GateEditModal extends Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  save() {
    this.parent.setState({
      hasInflight: true,
    });
    fetch(
      `${config.apiBase}/stargates/${this.state.gateId}/fuel_level?value=${this.state.gateFuel}`,
      { method: "PUT" }
    )
      .then((resp) => {
        if (resp.ok) {
          this.parent.setState({
            dataOverride: update(this.parent.getDS(), {
              [this.state.index]: {
                fuel_target: {
                  $set: this.state.gateFuel,
                },
              },
            }),
          });
          this.toggle();
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally((_) => {
        this.parent.setState({
          hasInflight: false,
        });
      });
  }
  activate(row) {
    this.setState({
      modal: true,
      index: row.ix,
      citname: row.structure_name,
      gateId: row.item_id,
      gateFuel: row.fuel_target,
    });
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
        >
          <ModalHeader toggle={() => this.toggle()}>
            {this.state.citname}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="gateFuel">Fuel Target</Label>
                <Input
                  type="text"
                  name="gateFuel"
                  id="gateFuel"
                  value={this.state.gateFuel}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.save()}>
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const gateEdit = React.createRef();

function onEditGate(row) {
  return function (evt) {
    evt.preventDefault();
    gateEdit.current.activate(row);
  };
}

function claimTask(self, row) {
  return function (evt) {
    self.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/tasks/gate_fuel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.item_id,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          self.setState({
            dataOverride: update(self.getDS(), {
              [row.ix]: {
                tasked_to: {
                  $set: "You",
                },
              },
            }),
          });
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally((_) => {
        self.setState({
          hasInflight: false,
        });
      });
  };
}

function actionsFormatter(self, cell, row) {
  if (row.tasked_to !== null) {
    return `Assigned to: ${row.tasked_to}`;
  }
  if (row.fuel_amount > row.fuel_target * 0.92) {
    return "";
  }
  return (
    <div>
      <Button color={"primary"} size={"sm"} onClick={claimTask(self, row)}>
        Claim task
      </Button>
    </div>
  );
}

function nameFormatter(cell, row) {
  return (
    <a href="#" onClick={onEditGate(row)}>
      {cell}
    </a>
  );
}

class Stargates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
    };
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    const spacerStyle = {
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "20px",
    };
    const actFmt = (cell, row) => actionsFormatter(this, cell, row);
    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        formatter: nameFormatter,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "120px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "90px",
        },
      },
      {
        text: "Fuel Amount",
        dataField: "fuel_amount",
        sort: true,
        formatter: (x) => x.toLocaleString(),
        headerStyle: {
          width: "130px",
        },
      },
      {
        text: "Fuel Target",
        dataField: "fuel_target",
        sort: true,
        formatter: (x) => x.toLocaleString(),
        headerStyle: {
          width: "130px",
        },
      },
      {
        text: `% of Target`,
        dataField: "target_percent",
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return a - b;
          }
          return b - a;
        },
        formatter: (x) => `${x.toLocaleString()}%`,
        headerStyle: {
          width: "90px",
        },
      },
      {
        text: "",
        dataField: "123456",
        isDummyField: true,
        formatter: actFmt,
        headerStyle: {
          width: "100px",
        },
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Stargate Fuel</h1>
            </Col>
            <Col>
              <Form className="float-right">
                <FormGroup check style={spacerStyle}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="showHidden"
                      id="inputShowHiddenCits"
                      checked={this.state.showHidden}
                      onChange={(e) => this.handleInputChange(e)}
                    />{" "}
                    Show Hidden Gates
                  </Label>
                </FormGroup>
              </Form>
              {spinner}
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"stargates"}
                rowFilter={addIndices}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"target_percent"}
                auxFilter={(a) => this.auxFilter(a)}
                fluid
              />
            </Col>
          </Row>
        </Container>
        <GateEditModal ref={gateEdit} parent={this} />
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
  calculateFuelPercent(data) {
    return data.map((d) => {
      if (d.fuel_amount || d.fuel_target) {
        return {
          ...d,
          target_percent: ((d.fuel_amount / d.fuel_target) * 100).toFixed(0),
        };
      }
      return d;
    });
  }
  auxFilter(data) {
    if (this.state.dataOverride) {
      data = this.state.dataOverride;
    } else {
      this.dataStash = data;
    }
    if (!this.state.showHidden) {
      data = data.filter((a) => {
        if (a.last_gatefueled_at === null) {
          return true;
        }
        return (
          new Date(Date.parse(a.last_gatefueled_at) + 14400000) < new Date()
        );
      });
    }
    return this.calculateFuelPercent(data);
  }
}

export default Stargates;
