import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { PacmanLoader } from "react-spinners";
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { sprintf } from "sprintf-js";

import { PermissionRequired, Permissions } from "./Access";
import { ActionTypes } from "./ActionTypes";
import { config } from "./config";
import { Countdown } from "./GlobalTime";
import MenuList from "./MenuList";
import { dotlanLink } from "./sharedFormatters";
import TimerHistory from "./TimerHistory";
import ROUTES, { createEditTimerUrl } from "./utils/routes";
import withRouter from "./utils/withRouter";

const Is400Response = (response) => {
  return Math.floor(response.status / 100) === 4;
};

const SupportedFinishedStates = [
  { id: 0, value: "Active" },
  { id: 1, value: "Saved" },
  { id: 2, value: "Destroyed" },
];

class ViewTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: {},
      isLoading: true,
      alert: null,
    };
  }

  isDeleted() {
    return this.state.timer.is_deleted;
  }

  componentDidMount() {
    fetch(`${config.apiBase}/timers/${this.props.router.params.timerId}`)
      .then((response) => {
        if (Is400Response(response)) {
          this.setState({
            timer: null,
          });
        } else {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({
          timer: data,
          isLoading: false,
        });
      });
  }

  getCountdown() {
    const date = new Date(this.state.timer.timer_expires);
    return <Countdown date={date} />;
  }

  getExitDate() {
    const date = new Date(this.state.timer.timer_expires);
    return sprintf(
      "%d-%02d-%02d %02d:%02d:%02d",
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  onFinishedStateChanged = (val) => {
    this.updateTimer({
      finished_state: val.value,
    });
  };

  updateTimer(payload) {
    // Make sure the alert is cleared each time
    this.setState({
      alert: null,
    });

    fetch(`${config.apiBase}/timers/${this.state.timer.id}/update`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then((response) => {
        this.setState({
          timer: response,
          alert: {
            color: "info",
            content: "Timer was updated.",
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteTimer() {
    if (
      !global.confirm(
        "Are you sure you want to do this! This is a destructive action!"
      )
    ) {
      return;
    }

    fetch(`${config.apiBase}/timers/${this.state.timer.id}/delete`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          throw response;
        }
      })
      .then((response) => {
        this.props.router.navigate(ROUTES.TIMERS);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getAlertBannerForTimer() {
    const alerts = [];
    if (this.isDeleted()) {
      alerts.push(
        <Alert color="warning">
          This Timer has been marked as deleted. If this was a mistake, please
          unarchive the timer by setting it to Active.
        </Alert>
      );
    }

    const { alert } = this.state;
    if (alert) {
      alerts.push(
        <Alert color={alert.color} toggle={this.hideAlert}>
          {alert.content}
        </Alert>
      );
    }
    return alerts;
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  canBeDeleted(finishedState) {
    return finishedState && finishedState.id > 0;
  }

  getDotlanUrl() {
    const { timer } = this.state;
    return dotlanLink(timer.solar_system);
  }

  getTimerContents(timer) {
    // Note: This could be better broken down
    // into components

    const finishedState = SupportedFinishedStates.find((item) => {
      return item.value === timer.finished_state;
    });

    return (
      <React.Fragment>
        <Row className="row-spacing">
          <Col>{this.getAlertBannerForTimer()}</Col>
        </Row>
        <Row>
          <Col xs={10}>
            <h1>{timer.structure_name}</h1>
            <h3>{timer.is_hostile ? "** HOSTILE **" : "** FRIENDLY **"}</h3>
            <b>Countdown: </b> {this.getCountdown()} <br />
            <b>Expires at: </b> {this.getExitDate()} <br />
            <b>Structure Type: </b> {timer.structure_type.type_name} <br />
            <b>Timer Type: </b> {timer.timer_type} <br />
            <b>Power State: </b> {timer.power_state} <br />
            <b>Owning Alliance Ticker: </b> {timer.owning_alliance_ticker}{" "}
            <br />
            <b>Owning Corp Ticker: </b> {timer.owning_corp_ticker} <br />
            <b>Solar System: </b> {timer.solar_system.solar_system_name} <br />
            <h4>Notes</h4>
            <div className="alert alert-secondary">
              <code className="linebreak-text">{timer.notes}</code>
            </div>
          </Col>
          <Col xs={2}>
            <h2>Actions</h2>
            <PermissionRequired role={Permissions.ADD_TIMER}>
              <Link
                className="btn btn-primary btn-block btn-spread"
                to={ROUTES.ADD_TIMER}
              >
                Create New Timer
              </Link>
            </PermissionRequired>
            <a
              className="btn btn-info btn-spread btn-block"
              href={this.getDotlanUrl()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Dotlan Map
            </a>
            <PermissionRequired role={Permissions.ADD_TIMER}>
              <Link
                onClick={() => this.props.setActiveTimer(timer)}
                to={createEditTimerUrl(timer.id)}
                className="btn btn-warning btn-block button-spread"
              >
                Edit
              </Link>
            </PermissionRequired>
            <FormGroup>
              <Label for="finishedState">Timer State</Label>
              <Select
                color="primary"
                components={{ MenuList }}
                id="finishedState"
                options={SupportedFinishedStates}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option["value"]}
                onChange={this.onFinishedStateChanged}
                value={finishedState}
              />
            </FormGroup>
            <PermissionRequired role={Permissions.DELETE_TIMER}>
              <Button
                color="danger"
                className="button-spread btn-block"
                onClick={this.deleteTimer.bind(this)}
              >
                Delete (Dangerous)
              </Button>
            </PermissionRequired>
          </Col>
        </Row>
        <TimerHistory timer_id={timer.id} />
      </React.Fragment>
    );
  }

  render() {
    const { timer, isLoading } = this.state;

    let contents;
    if (!isLoading) {
      contents = this.getTimerContents(timer);
    }

    return (
      <LoadingOverlay
        active={isLoading}
        fadeSpeed={500}
        spinner={<PacmanLoader />}
      >
        <Container>{contents}</Container>
      </LoadingOverlay>
    );
  }
}

export default connect(null, (dispatch) => ({
  setActiveTimer: (timer) => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_TIMER,
      timer,
    });
  },
}))(withRouter(ViewTimer));
