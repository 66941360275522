import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";

import AsyncTable from "./AsyncTable.js";

class Coreless extends Component {
  render() {
    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
      },
    ];

    return (
      <AsyncTable
        requestUrl={"citadels/coreless"}
        columnDefinition={columns}
        keyField={"item_id"}
      />
    );
  }
}

export default Coreless;
