import React, { Component } from "react";
import Select from "react-select";
import { ButtonGroup, Col, Container, Row } from "reactstrap";

import { PermissionRequired, Permissions } from "./Access";
import AsyncTable from "./AsyncTable.js";
import MenuList from "./MenuList";
import TimerColumns from "./TimerColumns";
import ROUTES from "./utils/routes";
import withRouter from "./utils/withRouter";

function parseDates(data) {
  data.map((entry) => {
    entry.created_at = new Date(entry.created_at);
    entry.timer_expires = new Date(entry.timer_expires);
    entry.timer_countdown = entry.timer_expires;

    return entry;
  });

  return data;
}

class ListTimersShared extends Component {
  static dashboardsList = [
    { id: 0, value: "Main" },
    { id: 1, value: "Abandoned" },
  ];
  constructor(props) {
    super(props);
    this.state = {
      activeDashboard: ListTimersShared.dashboardsList[0],
    };
  }
  setDashboard(val) {
    this.setState({
      activeDashboard: val,
    });
  }
  render() {
    const { header, url, archived, sort } = this.props;
    const { activeDashboard } = this.state;

    return (
      <div>
        <Container fluid>
          <Row className="row-spacing">
            <Col>
              <h1>{header}</h1>
            </Col>
            <Col xs="2" className="no-padding">
              <Select
                components={{ MenuList }}
                options={ListTimersShared.dashboardsList}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option["value"]}
                onChange={(val) => this.setDashboard(val)}
                value={activeDashboard}
              />
            </Col>
            <Col xs="2" className="no-padding">
              <ButtonGroup className="float-right">
                <PermissionRequired role={Permissions.ADD_TIMER}>
                  <button
                    className="btn btn-primary btn-lg"
                    onClick={() => this.props.router.navigate(ROUTES.ADD_TIMER)}
                  >
                    Add Timer
                  </button>
                </PermissionRequired>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={url + "?dashboard=" + activeDashboard.value}
                rowFilter={parseDates}
                columnDefinition={TimerColumns(archived)}
                keyField={"id"}
                sortDirection={sort}
                sortKey={"timer_expires"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const ListTimersSharedWithRouter = withRouter(ListTimersShared);

export function ListTimers() {
  return (
    <ListTimersSharedWithRouter
      header={"Timers"}
      url={"timers"}
      archived={false}
      sort={"asc"}
    />
  );
}

export function ListArchivedTimers() {
  return (
    <ListTimersSharedWithRouter
      header={"Archived Timers"}
      url={"timers/archived"}
      archived={true}
      sort={"desc"}
    />
  );
}
