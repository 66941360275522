import {
  faCheck,
  faRecycle,
  faScroll,
  faThumbsDown,
  faThumbsUp,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import update from "immutability-helper";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { config } from "./config";
import MenuList from "./MenuList";
import { dotlanLinkFormatter } from "./sharedFormatters";
import SolarSystemSelector from "./SolarSystemSelector";

function addIndices(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].ix = i;
  }
  return data;
}

function formatEnumAsTitle(enumString) {
  const reserved_list = ["id"];
  return (
    enumString &&
    enumString
      .toLowerCase()
      .split("_")
      .reduce(function (c, v) {
        c.push(
          reserved_list.indexOf(v) !== -1
            ? v.toUpperCase()
            : v.substring(0, 1).toUpperCase() + v.substring(1)
        );
        return c;
      }, [])
      .join(" ")
  );
}

class EnumSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.value,
      id: props.id || "systemSelect",
      onChange: props.onChange,
      optionValueFunc: props.optionValueFunc || ((a) => a["option_value_key"]),
      optionLabelFunc: props.optionLabelFunc || ((a) => a["option_label_key"]),
      optionLabelFormatter: props.optionLabelFormatter || ((a) => a),
      disabled: props.disabled,
      initialValue: undefined,
    };
    this.enumSelected = this.enumSelected.bind(this);
  }
  componentDidMount() {
    fetch(`${config.apiBase}/${this.props.source}`)
      .then((response) => response.json())
      .then((data) => {
        const { selectedOption, optionLabelFunc } = this.state;
        if (this.props.sourceStatic) {
          // Prepend the static options.
          data = this.props.sourceStatic.concat(data);
        }
        this.setState({
          options: data,
          initialValue:
            data.find((v) => optionLabelFunc(v) === selectedOption) ||
            selectedOption,
        });
      });
  }
  enumSelected(val) {
    this.setState({
      selectedOption: val,
      initialValue: val,
    });
    if (this.state.onChange) {
      this.state.onChange(val);
    }
  }
  prefixOnlyFilter(item, currentText) {
    const { label } = item;
    const lowercaseLabel = label.toString().toLowerCase();
    return lowercaseLabel.startsWith(currentText.toLowerCase());
  }
  render() {
    const {
      id,
      required,
      options,
      optionValueFunc,
      optionLabelFunc,
      optionLabelFormatter,
      disabled,
      initialValue,
    } = this.state;
    return (
      <Select
        components={{ MenuList }}
        id={id}
        options={options}
        onChange={this.enumSelected}
        filterOption={this.prefixOnlyFilter}
        getOptionValue={optionValueFunc}
        getOptionLabel={(a) => optionLabelFormatter(optionLabelFunc(a))}
        isDisabled={disabled}
        value={initialValue}
        required={required}
      />
    );
  }
}

class TaskEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  save() {
    fetch(`${config.apiBase}/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: this.state.id,
        task_type: this.state.task_type,
        status: this.state.status,
        solar_system: this.state.solar_system,
        assigned_to: this.state.assigned_to
          ? this.state.assigned_to.id
          : undefined,
        notes: this.state.notes,
        priority: this.state.priority,
        count: this.state.count,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          document.location.reload();
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      });
  }
  activate(row) {
    this.setState({
      modal: true,
      id: row && row.id,
      task_type: row && row.task_type,
      status: (row && row.status) || "ACTIVE",
      solar_system_ref: row && row.solar_system,
      solar_system:
        row && row.solar_system && row.solar_system.solar_system_name,
      assigned_to: row && row.assigned_to,
      notes: row && row.notes,
      priority: (row && row.priority && row.priority.name) || "LOW",
      access: (row && row.access) || "INVISIBLE",
      count: 1,
    });
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }
  render() {
    const textareaStyle = {
      width: "100%",
    };
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
          backdrop={"static"}
        >
          <ModalHeader toggle={() => this.toggle()}>
            {this.state.id ? "Edit" : "Create"} Task {this.state.id}{" "}
            {this.state.citname}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="task_type" className="required">
                  Type
                </Label>
                <EnumSelector
                  source={"access/my_accessible_task_types"}
                  value={this.state.task_type}
                  optionValueFunc={(o) => o["id"]}
                  optionLabelFunc={(o) => o["name"]}
                  onChange={(v) => {
                    this.setState({ task_type: { name: v.name, id: v.id } });
                  }}
                  disabled={this.state.id}
                />
              </FormGroup>
              <FormGroup>
                <Label for="solar_system" className="required">
                  System
                </Label>
                <SolarSystemSelector
                  value={this.state.solar_system_ref}
                  onChange={(v) => {
                    this.setState({
                      solar_system: v.solar_system_name,
                      solar_system_id: v.solar_system_id,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="assigned_to" className="">
                  Assigned To
                </Label>
                <EnumSelector
                  sourceStatic={[{ id: null, display_name: "(unassigned)" }]}
                  source={"access/user_list"}
                  value={this.state.assigned_to}
                  optionValueFunc={(o) => o["id"]}
                  optionLabelFunc={(o) => o["display_name"]}
                  onChange={(v) => {
                    this.setState({
                      assigned_to: v.id !== null ? v : null,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="inputNotes">Notes</Label>
                <Input
                  type="textarea"
                  id="inputNotes"
                  name="notes"
                  style={textareaStyle}
                  rows="4"
                  cols="1000"
                  value={this.state.notes}
                  onChange={(e) => this.handleInputChange(e)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="priority" className="required">
                  Priority
                </Label>
                <EnumSelector
                  source={"enums/priority_level"}
                  value={this.state.priority}
                  optionValueFunc={(o) => o["value"]}
                  optionLabelFunc={(o) => o["name"]}
                  optionLabelFormatter={formatEnumAsTitle}
                  onChange={(v) => {
                    this.setState({ priority: v.name });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="status" className="required">
                  Status
                </Label>
                <EnumSelector
                  source={"enums/task_status"}
                  value={this.state.status}
                  optionValueFunc={(o) => o["value"]}
                  optionLabelFunc={(o) => o["name"]}
                  optionLabelFormatter={formatEnumAsTitle}
                  onChange={(v) => {
                    this.setState({ status: v.name });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="count" className="">
                  Number of Copies
                </Label>
                <Input
                  min={1}
                  max={100}
                  step="1"
                  type="number"
                  value={this.state.count}
                  onChange={(v) => {
                    this.setState({ count: v.target.valueAsNumber });
                  }}
                  disabled={this.state.id}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => this.save()}
              disabled={
                !(
                  this.state.task_type &&
                  this.state.solar_system &&
                  this.state.priority
                )
              }
            >
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => this.toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class TaskHistoryViewerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  activate(row) {
    this.setState({
      modal: true,
      id: row && row.id,
      current_task_id: row && row.id,
    });
  }
  render() {
    const columns = [
      {
        text: "Timestamp",
        dataField: "timestamp",
        sort: true,
        formatter: shortDateTime,
      },
      {
        text: "Event",
        dataField: "event",
      },
    ];
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.toggle()}
          unmountOnClose={true}
          backdrop={"static"}
          size={"lg"}
        >
          <ModalHeader toggle={() => this.toggle()}>
            View Task {this.state.id} History
          </ModalHeader>
          <ModalBody>
            <AsyncTable
              requestUrl={"task_history/" + this.state.current_task_id}
              columnDefinition={columns}
              keyField={"id"}
              sortKey={"timestamp"}
              fluid
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.toggle()}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function onViewHistoryTask(row) {
  return function (evt) {
    evt.preventDefault();
    taskHistoryView.current.activate(row);
  };
}

function onUpdateStatus(self, row, action) {
  return function (evt) {
    self.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/task-status`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.id,
        action: action,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          if (
            action === "complete" ||
            action === "fail" ||
            action === "cancel"
          ) {
            self.setState({
              dataOverride: update(self.getDS(), {
                [row.ix]: {
                  status: {
                    $set: action,
                  },
                },
              }),
            });
          } else {
            document.location.reload();
          }
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally((_) => {
        self.setState({
          hasInflight: false,
        });
      });
  };
}

function actionsFormatter(self, row) {
  return (
    <div>
      {row.can_claim && (
        <Button
          color={"primary"}
          size={"sm"}
          id={"claim-" + row.id}
          onClick={onUpdateStatus(self, row, "claim")}
        >
          <FontAwesomeIcon icon={faCheck} fixedWidth />
          <UncontrolledTooltip placement="top" target={"claim-" + row.id}>
            Claim Task
          </UncontrolledTooltip>
        </Button>
      )}
      {row.can_release && (
        <Button
          color={"primary"}
          size={"sm"}
          id={"release-" + row.id}
          onClick={onUpdateStatus(self, row, "release")}
        >
          <FontAwesomeIcon icon={faRecycle} fixedWidth />
          <UncontrolledTooltip placement="top" target={"release-" + row.id}>
            Release Task
          </UncontrolledTooltip>
        </Button>
      )}
      {row.can_fail_complete && (
        <Button
          color={"danger"}
          size={"sm"}
          id={"fail-" + row.id}
          onClick={onUpdateStatus(self, row, "fail")}
        >
          <FontAwesomeIcon icon={faThumbsDown} fixedWidth />
          <UncontrolledTooltip placement="top" target={"fail-" + row.id}>
            Fail Task
          </UncontrolledTooltip>
        </Button>
      )}
      {row.can_cancel && (
        <Button
          color={"warning"}
          size={"sm"}
          id={"canceled-" + row.id}
          onClick={onUpdateStatus(self, row, "cancel")}
        >
          <FontAwesomeIcon icon={faTimes} fixedWidth />
          <UncontrolledTooltip placement="top" target={"canceled-" + row.id}>
            Cancel Task
          </UncontrolledTooltip>
        </Button>
      )}
      {row.can_fail_complete && (
        <Button
          color={"success"}
          size={"sm"}
          id={"completed-" + row.id}
          onClick={onUpdateStatus(self, row, "complete")}
        >
          <FontAwesomeIcon icon={faThumbsUp} fixedWidth />
          <UncontrolledTooltip placement="top" target={"completed-" + row.id}>
            Complete Task
          </UncontrolledTooltip>
        </Button>
      )}
      <Button
        color={"primary"}
        size={"sm"}
        id={"view-history-" + row.id}
        onClick={onViewHistoryTask(row)}
      >
        <FontAwesomeIcon icon={faScroll} fixedWidth />
        <UncontrolledTooltip placement="top" target={"view-history-" + row.id}>
          View History
        </UncontrolledTooltip>
      </Button>
    </div>
  );
}

function defaultFormatter(value, cell) {
  return function (cell) {
    return cell || value || "(undefined)";
  };
}

function shortDateTime(cell) {
  return cell.substring(0, 10) + " " + cell.substring(11, 19);
}

const taskEdit = React.createRef();
const taskHistoryView = React.createRef();

class Tasks extends Component {
  constructor(props) {
    super(props);
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    this.state = {
      year,
      month,
      oldYear: 0,
      oldMonth: 0,
    };
  }
  handleSelector(key, val) {
    this.setState({
      [key]: val,
    });
  }
  render() {
    const { year, month } = this.state;
    const actFmt = (cell, row) => actionsFormatter(this, row);
    const columns = [
      {
        text: "Type",
        dataField: "task_type.name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Status",
        dataField: "status",
        sort: true,
        filter: textFilter(),
        formatter: formatEnumAsTitle,
      },
      {
        text: "Solar",
        dataField: "solar_system",
        sort: true,
        filter: textFilter(),
        formatter: dotlanLinkFormatter,
        filterValue: (v) => v.solar_system_name,
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Notes",
        dataField: "notes",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Assignee",
        dataField: "assigned_to.display_name",
        sort: true,
        filter: textFilter(),
        formatter: defaultFormatter("Unassigned"),
      },
      {
        text: "Priority",
        dataField: "priority",
        sort: true,
        filter: textFilter(),
        formatter: formatEnumAsTitle,
      },
      {
        text: "Actions",
        sort: false,
        dataField: "id",
        formatter: actFmt,
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    let addButton = "";
    if (this.props.activeOnly) {
      addButton = (
        <Col>
          <Button
            color={"primary"}
            className="float-right"
            onClick={() => taskEdit.current.activate()}
          >
            Add
          </Button>
          {spinner}
        </Col>
      );
    } else {
      const curYear = new Date().getFullYear();
      const yearOptions = [curYear, curYear - 1, curYear - 2];
      const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      addButton = (
        <>
          <Col xs="2">
            <Select
              components={{ MenuList }}
              options={yearOptions}
              onChange={(val) => this.handleSelector("year", val)}
              getOptionValue={(x) => x}
              getOptionLabel={(x) => x}
              value={year}
            />
          </Col>
          <Col xs="2">
            <Select
              components={{ MenuList }}
              options={monthOptions}
              onChange={(val) => this.handleSelector("month", val)}
              getOptionValue={(x) => x}
              getOptionLabel={(x) => x}
              value={month}
            />
          </Col>
        </>
      );
    }
    let url;
    if (this.props.activeOnly) {
      url = this.props.url;
    } else {
      url = `${this.props.url}?year=${year}&month=${month}`;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>{this.props.name}</h1>
            </Col>
            {addButton}
          </Row>
        </Container>
        <AsyncTable
          requestUrl={url}
          rowFilter={addIndices}
          columnDefinition={columns}
          keyField={"id"}
          sortKey={"task_type"}
          auxFilter={(a) => this.auxFilter(a)}
          fluid
        />
        <TaskEditorModal ref={taskEdit} />
        <TaskHistoryViewerModal ref={taskHistoryView} />
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
  auxFilter(data) {
    if (this.state.dataOverride) {
      data = this.state.dataOverride;
    } else {
      this.dataStash = data;
    }
    if (this.props.activeOnly) {
      return data.filter(
        (item) => item.status === "ACTIVE" || item.status === "UNASSIGNED"
      );
    } else {
      return data;
    }
  }
}

export function ActiveTasks() {
  return <Tasks url="tasks/active" name="Active Tasks" activeOnly={true} />;
}

export function ArchivedTasks() {
  return (
    <Tasks url="tasks/archived" name="Archived Tasks" activeOnly={false} />
  );
}
