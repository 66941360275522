import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import beaconIcon from "./icons/pngs/beacon.png";
import entrapmentFadedIcon from "./icons/pngs/entrapment-faded.png";
import jammerIcon from "./icons/pngs/jammer.png";
import miningFadedIcon from "./icons/pngs/mining-faded.png";
import rattingFadedIcon from "./icons/pngs/ratting-faded.png";
import stargateIcon from "./icons/pngs/stargate.png";
import supercapIcon from "./icons/pngs/supercap.png";
import surveyFadedIcon from "./icons/pngs/survey-faded.png";
import wormholeFadedIcon from "./icons/pngs/wormhole-faded.png";

function toggleableUpgradeFormatter(src) {
  return function (cell) {
    const textstyle = {
      lineHeight: "72px",
      fontSize: "24px",
      color: "#FFFFFF",
      verticalAlign: "middle",
      textAlign: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      fontWeight: "bold",
      width: "72px",
      height: "72px",
    };
    if (cell === "NOT_INSTALLED") {
      return <div style={textstyle}></div>;
    }
    const style = {};
    const style1 = {
      margin: "6px",
    };
    if (cell === "OFFLINE") {
      style.filter = "grayscale(100%)";
    }
    return (
      <div style={style}>
        <img style={style1} src={src} alt="" />
      </div>
    );
  };
}
function toRoman(num) {
  return ["1", "2", "3", "4", "5"][num - 1];
}
function levelableUpgradeFormatter(src) {
  return function (cell) {
    const textstyle = {
      lineHeight: "72px",
      fontSize: "24px",
      color: "#FFFFFF",
      verticalAlign: "middle",
      textAlign: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      fontWeight: "bold",
      width: "72px",
      height: "72px",
    };
    if (cell === 0) {
      return <div style={textstyle}></div>;
    }
    const style = {
      backgroundImage: "url(" + src + ")",
      lineHeight: "72px",
      fontSize: "24px",
      color: "#FFFFFF",
      verticalAlign: "middle",
      textAlign: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      fontWeight: "bold",
    };
    return <div style={style}>{toRoman(cell)}</div>;
  };
}

class IHubs extends Component {
  render() {
    const columns = [
      {
        text: "System",
        dataField: "solar_system.solar_system_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Sec Status",
        dataField: "solar_system.security",
        sort: true,
        formatter: (x) => (Math.round(x * 100) / 100).toLocaleString(),
      },
      {
        text: "Bridge",
        dataField: "stargate_state",
        formatter: toggleableUpgradeFormatter(stargateIcon),
        sort: true,
      },
      {
        text: "Beacon",
        dataField: "cynogen_state",
        formatter: toggleableUpgradeFormatter(beaconIcon),
        sort: true,
      },
      {
        text: "Jammer",
        dataField: "cynojam_state",
        formatter: toggleableUpgradeFormatter(jammerIcon),
        sort: true,
      },
      {
        text: "SuperCap",
        dataField: "scap_construction_state",
        formatter: toggleableUpgradeFormatter(supercapIcon),
        sort: true,
      },
      {
        text: "Ore",
        dataField: "ore_prospecting_level",
        formatter: levelableUpgradeFormatter(miningFadedIcon),
        sort: true,
      },
      {
        text: "Survey",
        dataField: "survey_networks_level",
        formatter: levelableUpgradeFormatter(surveyFadedIcon),
        sort: true,
      },
      {
        text: "Entrapment",
        dataField: "entrapment_level",
        formatter: levelableUpgradeFormatter(entrapmentFadedIcon),
        sort: true,
      },
      {
        text: "Pirate",
        dataField: "pirate_detection_level",
        formatter: levelableUpgradeFormatter(rattingFadedIcon),
        sort: true,
      },
      {
        text: "Shitholes",
        dataField: "wormgenerator_level",
        formatter: levelableUpgradeFormatter(wormholeFadedIcon),
        sort: true,
      },
    ];

    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Infrastructure Hub Upgrades</h1>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"ihubs"}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"solar_system.solar_system_name"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default IHubs;
