import update from "immutability-helper";
import { createStore } from "redux";

import { ActionTypes } from "./ActionTypes";

export function reducer(state, action) {
  if (state === undefined) {
    state = {
      permissions: new Set(),
      activeTimer: null,
    };
  }
  switch (action.type) {
    case ActionTypes.SET_PERMISSIONS:
      return update(state, {
        permissions: {
          $set: action.permissions,
        },
      });
    case ActionTypes.SET_ACTIVE_TIMER:
      return update(state, {
        activeTimer: {
          $set: action.timer,
        },
      });
    default:
      return state;
  }
}

export const rc2MainStore = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ serialize: true })
);
