import React, { Component } from "react";
import { Jumbotron } from "reactstrap";

class Login extends Component {
  render() {
    return (
      <Jumbotron>
        Please <a href="/api/login">log in</a>
      </Jumbotron>
    );
  }
}

export default Login;
