import React, { Component } from "react";
import { Container, Jumbotron } from "reactstrap";

class TokenSubmitSuccess extends Component {
  render() {
    return (
      <Container fluid>
        <Jumbotron fluid className="alert">
          <Container fluid>Your token was recorded in the system</Container>
        </Jumbotron>
      </Container>
    );
  }
}

export default TokenSubmitSuccess;
