import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import withRouter from "./utils/withRouter";

const BlueprintsWithRouter = withRouter(Blueprints);

class Blueprints extends Component {
  render() {
    let { marketgroup_id, title } = this.props;

    const columns = [
      {
        text: "Corporation",
        dataField: "corporation.name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "175px",
        },
      },
      {
        text: "Location",
        dataField: "description",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Total Runs",
        dataField: "amount",
        sort: true,
        formatter: (x) => x.toLocaleString(),
        headerStyle: {
          width: "100px",
        },
      },
    ];
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>{title} BPCs</h1>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"blueprints_report?market_group=" + marketgroup_id}
                columnDefinition={columns}
                keyField={"id"}
                sortKey={"id"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Blueprints;
