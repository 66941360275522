import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import { Button, Container } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { config } from "./config";
import MenuList from "./MenuList";

let timerModeOptions = [
  {
    id: "NOTHING",
    name: "Nothing",
    idx: 0,
  },
  {
    id: "NOTES_ONLY",
    name: "Notifications",
    idx: 1,
  },
  {
    id: "NOTES_AND_CITS",
    name: "Notifications and citadels",
    idx: 2,
  },
  {
    id: "EVERYTHING",
    name: "Everything",
    idx: 3,
  },
];

function updateTimerMode(val, id) {
  fetch(`${config.apiBase}/tokens/${id}/mode`, {
    method: "PUT",
    body: JSON.stringify({
      mode: val.idx,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      document.location.reload();
    } else {
      alert("Something went wrong");
    }
  });
}

function timerModeFormatter(cell, row) {
  for (let opt of timerModeOptions) {
    if (opt.id === cell) {
      var selectedValue = opt;
      break;
    }
  }
  return (
    <Select
      components={{ MenuList }}
      options={timerModeOptions}
      onChange={(val) => updateTimerMode(val, row.id)}
      getOptionValue={(option) => option["id"]}
      getOptionLabel={(options) => options["name"]}
      value={selectedValue}
    />
  );
}

function deleteTimer(id) {
  fetch(`${config.apiBase}/tokens/${id}`, { method: "DELETE" }).then(
    (response) => {
      if (response.ok) {
        document.location.reload();
      } else {
        alert("Something went wrong");
      }
    }
  );
}

function actionsFormatter(cell, row) {
  return (
    <Button size="sm" onClick={() => deleteTimer(row.id)} color="danger">
      Delete
    </Button>
  );
}

function textWithDefault(def) {
  return function (cell) {
    if (cell == null) {
      return def;
    } else {
      return cell;
    }
  };
}

class ListTokens extends Component {
  render() {
    const columns = [
      {
        text: "Character",
        dataField: "character.name",
        sort: true,
        filter: textFilter(),
        formatter: textWithDefault("#Not verified"),
      },
      {
        text: "Corp",
        dataField: "corp.name",
        sort: true,
        filter: textFilter(),
        formatter: textWithDefault("#Not verified"),
      },
      {
        text: "Added by",
        dataField: "added_by.username",
        sort: true,
        filter: textFilter(),
        formatter: textWithDefault("#System"),
      },
      {
        text: "Status",
        dataField: "status",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Mode",
        dataField: "mode",
        sort: true,
        filter: textFilter(),
        formatter: timerModeFormatter,
      },
      {
        text: "",
        dataField: "123",
        isDummyField: true,
        formatter: actionsFormatter,
        headerStyle: {
          width: "90px",
        },
        style: {
          textAlign: "center",
        },
      },
    ];
    return (
      <div>
        <Container fluid>
          <AsyncTable
            requestUrl={"tokens"}
            columnDefinition={columns}
            keyField={"id"}
            sortKey={"corp.name"}
            fluid
          />
        </Container>
      </div>
    );
  }
}

export default ListTokens;
