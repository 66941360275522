import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { dateFormatter } from "./sharedFormatters";

function parseDates(data) {
  data.map((entry) => {
    entry.timestamp = new Date(entry.timestamp);
    if ("exit_time" in entry) {
      entry.exit_time = new Date(entry.exit_time);
    }
    return entry;
  });
  return data;
}

const sharedColumns = [
  {
    text: "Timestamp",
    dataField: "timestamp",
    formatter: dateFormatter,
    sort: true,
  },
  {
    text: "Name",
    dataField: "structure_name",
    sort: true,
    filter: textFilter(),
  },
  {
    text: "Region",
    dataField: "solar_system.region.region_name",
    sort: true,
    filter: textFilter(),
  },
  {
    text: "Const",
    dataField: "solar_system.constellation.constellation_name",
    sort: true,
    filter: textFilter(),
  },
  {
    text: "Structure Type",
    dataField: "structure_type.type_name",
    sort: true,
    filter: textFilter(),
  },
];

class NotificationTab extends Component {
  render() {
    const { extraColumns, url } = this.props;
    const columns = [...sharedColumns, ...extraColumns];
    return (
      <AsyncTable
        requestUrl={"notifications/" + url}
        columnDefinition={columns}
        sortKey={"timestamp"}
        sortDirection={"desc"}
        rowFilter={parseDates}
        keyField={"id"}
        fluid
      />
    );
  }
}

function StructureUnderAttack() {
  const columns = [
    {
      text: "Attacker Corp",
      dataField: "corporation.name",
      sort: true,
      filter: textFilter(),
    },
    {
      text: "Attacker Alliance",
      dataField: "alliance.name",
      sort: true,
      filter: textFilter(),
    },
  ];
  return (
    <NotificationTab url={"structure_under_attack"} extraColumns={columns} />
  );
}

function OwnershipTransferred() {
  const columns = [
    {
      text: "Old owner",
      dataField: "old_corp.name",
      sort: true,
      filter: textFilter(),
    },
    {
      text: "New Owner",
      dataField: "new_corp.name",
      sort: true,
      filter: textFilter(),
    },
  ];

  return (
    <NotificationTab url={"ownership_transferred"} extraColumns={columns} />
  );
}

function StructureReinforced() {
  const columns = [
    {
      text: "Exit Time",
      dataField: "exit_time",
      formatter: dateFormatter,
      sort: true,
    },
    {
      text: "Defense Layer",
      dataField: "defense_layer",
      sort: true,
      filter: textFilter(),
    },
  ];

  return (
    <NotificationTab url={"structure_reinforced"} extraColumns={columns} />
  );
}

function StructureAnchoring() {
  return <NotificationTab url={"structure_anchoring"} extraColumns={[]} />;
}

function StructureOnline() {
  return <NotificationTab url={"structure_online"} extraColumns={[]} />;
}

export default class NotificationsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  render() {
    const { activeTab } = this.state;
    return (
      <div>
        <Nav tabs className="ml-3 mr-3 mt-3">
          <NavItem>
            <NavLink
              className={activeTab === 0 ? "active" : ""}
              onClick={() => this.toggle(0)}
            >
              Structure Under Attack
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 1 ? "active" : ""}
              onClick={() => this.toggle(1)}
            >
              Ownership Trasnferred
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 2 ? "active" : ""}
              onClick={() => this.toggle(2)}
            >
              Structure Reinforced
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 3 ? "active" : ""}
              onClick={() => this.toggle(3)}
            >
              Structure Anchoring
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === 4 ? "active" : ""}
              onClick={() => this.toggle(4)}
            >
              Structure Online
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={0}>
            <StructureUnderAttack />
          </TabPane>
          <TabPane tabId={1}>
            <OwnershipTransferred />
          </TabPane>
          <TabPane tabId={2}>
            <StructureReinforced />
          </TabPane>
          <TabPane tabId={3}>
            <StructureAnchoring />
          </TabPane>
          <TabPane tabId={4}>
            <StructureOnline />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
