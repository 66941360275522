import React, { Component } from "react";
import InputMask from "react-input-mask";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { PermissionRequired, Permissions } from "./Access";
import { config } from "./config";
import MenuList from "./MenuList";
import SolarSystemSelector from "./SolarSystemSelector";
import { createViewTimerUrl } from "./utils/routes";
import withRouter from "./utils/withRouter";

// Note: Could this be driven by a config on the backend?
const DefaultNotes = `
(Location:)`;

const SupportedTimerTypes = [
  { id: 0, value: "Anchoring" },
  { id: 1, value: "Armor" },
  { id: 2, value: "Hull" },
];

const SupportedPowerStates = [
  { id: 0, value: "Low" },
  { id: 1, value: "High" },
];

const DashboardsList = [
  { id: 0, value: "Main" },
  { id: 1, value: "Shadow" },
];

function exitTimeValid(time) {
  return !isNaN(new Date(time).getTime());
}

const countdownRe = /^(\d{2})d (\d{2})h (\d{2})m (\d{2})s$/;

function parseCountdownInput(input) {
  const parts = input.split(countdownRe);
  if (parts.length !== 6) {
    return null;
  }
  const intParts = parts.map((e) => parseInt(e));
  const [, days, hours, minutes, seconds] = intParts;

  if (days > 14) {
    return null;
  }

  if (hours > 23 || minutes > 59 || seconds > 59) {
    return null;
  }

  const offset = (seconds + minutes * 60 + hours * 3600 + days * 86400) * 1000;
  if (offset === 0) {
    return null;
  }

  return new Date(Date.now() + offset).toISOString();
}

class TimerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timerType: "",
      corpTicker: "",
      allianceTicker: "",
      notes: DefaultNotes,
      selectedStructure: null,
      selectedSystem: null,
      structureTypes: [],
      structureName: "",
      timerExpiration: "",
      timerExit: "",
      isHostile: null,
      powerState: "",
      priority: null,
      isAbsolute: false,
      dashboard: DashboardsList[0],
    };

    if (this.props.timer) {
      this.createStateFromTimerProp();
    }
  }

  createStateFromTimerProp() {
    // Note: It'd be great if we had a model for something like this
    const { timer } = this.props;

    let timerType = "";
    for (let i = 0; i < SupportedTimerTypes.length; i++) {
      if (SupportedTimerTypes[i].value === timer.timer_type) {
        timerType = SupportedTimerTypes[i];
        break;
      }
    }

    let powerState = "";
    for (let i = 0; i < SupportedPowerStates.length; i++) {
      if (SupportedPowerStates[i].value === timer.power_state) {
        powerState = SupportedPowerStates[i];
        break;
      }
    }

    let system = null;
    if (timer.solar_system) {
      system = {
        id: timer.solar_system_id,
        name: timer.solar_system.solar_system_name,
      };
    }

    this.setState({
      // Defaults
      structureTypes: [],
      // Timer Properties
      timerType: timerType,
      corpTicker: timer.owning_corp_ticker || "",
      allianceTicker: timer.owning_alliance_ticker || "",
      notes: timer.notes,
      selectedStructure: timer.structure_type,
      selectedSystem: system,
      structureName: timer.structure_name,
      timerExpiration: "",
      timerExit: "",
      isHostile: timer.is_hostile,
      powerState: powerState,
      isAbsolute: false,
      dashboard: DashboardsList[timer.dashboard.id],
    });
  }

  existingTimer() {
    return this.props.timer && this.props.timer.id;
  }

  systemSelected(val) {
    this.setState({
      selectedSystem: val,
    });
  }

  componentDidMount() {
    fetch(`${config.apiBase}/structure_types.json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          structureTypes: data,
        });
      });
  }

  isSubmitDisabled = () => {
    // If this is an existing timer, blank fields will just not be passed through the update
    if (this.existingTimer()) {
      return false;
    }
    const state = this.state;
    let timeValid;
    if (state.isAbsolute) {
      timeValid = exitTimeValid(state.timerExit);
    } else {
      timeValid = parseCountdownInput(state.timerExpiration) !== null;
    }
    return (
      state.selectedSystem === null ||
      state.selectedStructure === null ||
      state.structureName === null ||
      state.timerType === null ||
      !timeValid
    );
  };

  onTickersChange = (event) => {
    const input = event.target;
    const stateKey = event.target.id;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    const stateUpdate = {};
    stateUpdate[stateKey] = input.value.toUpperCase();

    this.setState(stateUpdate, () => input.setSelectionRange(start, end));
  };

  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSelector(name, val) {
    this.setState({
      [name]: val,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      priority,
      timerType,
      powerState,
      selectedSystem,
      selectedStructure,
      dashboard,
      allianceTicker,
      corpTicker,
      structureName,
      notes,
      isHostile,
      timerExpiration,
      isAbsolute,
      timerExit,
    } = this.state;
    let timer;
    if (isAbsolute && timerExit) {
      timer = timerExit;
    } else if (!isAbsolute && timerExpiration) {
      timer = parseCountdownInput(timerExpiration);
    } else {
      timer = undefined;
    }
    const data = {
      structure_name: structureName ? structureName : undefined,
      timer_type: timerType ? timerType.value : undefined,
      power_state: powerState ? powerState.value : undefined,
      solar_system_id: selectedSystem
        ? selectedSystem.solar_system_id
        : undefined,
      structure_type_id: selectedStructure
        ? selectedStructure.type_id
        : undefined,
      owning_alliance_ticker: allianceTicker ? allianceTicker : undefined,
      owning_corp_ticker: corpTicker ? corpTicker : undefined,
      timer_expires: timer,
      notes: notes ? notes : undefined,
      priority: priority !== null ? Number(priority) : undefined,
      is_hostile: isHostile !== null ? isHostile : undefined,
      finished_state: "Active",
      dashboard_id: dashboard.id,
    };

    let url = `${config.apiBase}/timers`;
    if (this.existingTimer()) {
      url += `/${this.props.timer.id}/update`;
    } else {
      url += `/create`;
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((response) => {
        this.props.router.navigate(createViewTimerUrl(response.id));
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  render() {
    const {
      structureName,
      timerType,
      powerState,
      selectedSystem,
      selectedStructure,
      allianceTicker,
      corpTicker,
      structureTypes,
      isHostile,
      notes,
      priority,
      isAbsolute,
      dashboard,
    } = this.state;
    let timerWidget;
    if (isAbsolute) {
      const timerValid = exitTimeValid(this.state.timerExit);
      timerWidget = (
        <FormGroup>
          <Label for="exitTime" className="required">
            Exit Timer
          </Label>
          <InputMask
            id={"exitTime"}
            mask={"2099-99-99T99:99:99Z"}
            onChange={(evt) => this.handleInputChange(evt)}
            name={"timerExit"}
            valid={timerValid}
            invalid={!timerValid}
          >
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </FormGroup>
      );
    } else {
      const countdownValid =
        parseCountdownInput(this.state.timerExpiration) !== null;
      timerWidget = (
        <FormGroup>
          <Label for="countdown" className="required">
            Countdown
          </Label>
          <InputMask
            id={"countdown"}
            mask={"99d 99h 99m 99s"}
            onChange={(evt) => this.handleInputChange(evt)}
            name={"timerExpiration"}
            valid={countdownValid}
            invalid={!countdownValid}
          >
            {(inputProps) => <Input {...inputProps} />}
          </InputMask>
        </FormGroup>
      );
    }

    // TODO mlohstroh: break this huge render function out to multiple
    // components
    return (
      <Form onSubmit={(event) => this.handleSubmit(event)}>
        <Row>
          <Col>
            <FormGroup>
              <Label for="systemSelect" className="required">
                System
              </Label>
              <SolarSystemSelector
                value={selectedSystem}
                onChange={(val) => this.systemSelected(val)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="structureName" className="required">
                Structure Name
              </Label>
              <Input
                id="structureName"
                onChange={(evt) => this.handleInputChange(evt)}
                name={"structureName"}
                value={structureName}
                invalid={!structureName}
                valid={!!structureName}
              />
            </FormGroup>

            <FormGroup>
              <Label for="typeSelect" className="required">
                Structure types
              </Label>
              <Select
                components={{ MenuList }}
                id="typeSelect"
                options={structureTypes}
                onChange={(val) =>
                  this.handleSelector("selectedStructure", val)
                }
                getOptionValue={(option) => option["type_id"]}
                getOptionLabel={(options) => options["type_name"]}
                value={selectedStructure}
              />
            </FormGroup>

            <FormGroup>
              <Label for="timerType" className="required">
                Timer Type
              </Label>
              <Select
                components={{ MenuList }}
                id="timerType"
                options={SupportedTimerTypes}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option["value"]}
                onChange={(val) => this.handleSelector("timerType", val)}
                value={timerType}
              />
            </FormGroup>

            <FormGroup>
              <Label for="powerState">Power State</Label>
              <Select
                components={{ MenuList }}
                id="powerState"
                options={SupportedPowerStates}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option["value"]}
                onChange={(val) => this.handleSelector("powerState", val)}
                value={powerState}
              />
            </FormGroup>
            <FormGroup className="form-check">
              <Input
                id="hostile"
                className="form-check-input"
                type="checkbox"
                checked={isHostile}
                name="isHostile"
                onClick={(evt) => this.handleInputChange(evt)}
              />
              <Label for="hostile" className="form-check-label">
                Hostile Structure?
              </Label>
            </FormGroup>
            <PermissionRequired role={Permissions.SET_TIMER_PRIORITY}>
              <FormGroup>
                <Label for="priority">Priority (0-3)</Label>
                <Input
                  id="priority"
                  name={"priority"}
                  onChange={(evt) => this.handleInputChange(evt)}
                  value={priority}
                />
              </FormGroup>
            </PermissionRequired>
            <FormGroup>
              <Label for="powerState">Dashboard</Label>
              <Select
                components={{ MenuList }}
                id="dashboard"
                options={DashboardsList}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option["value"]}
                onChange={(val) => this.handleSelector("dashboard", val)}
                value={dashboard}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="corpTicker">Corp ticker</Label>
              <Input
                id={"corpTicker"}
                maxLength={5}
                placeholder={"Corp ticker"}
                onChange={this.onTickersChange}
                value={corpTicker}
              />
            </FormGroup>

            <FormGroup>
              <Label for="allianceTicker">Alliance ticker</Label>
              <Input
                id={"allianceTicker"}
                maxLength={5}
                placeholder={"Alliance ticker"}
                onChange={this.onTickersChange}
                value={allianceTicker}
              />
            </FormGroup>

            <FormGroup>
              <Label for="notes">Notes</Label>
              <Input
                type="textarea"
                id="notes"
                rows={10}
                value={notes}
                name={"notes"}
                onChange={(evt) => this.handleInputChange(evt)}
              />
            </FormGroup>
            <FormGroup className="form-check">
              <Input
                id="absoluteTimer"
                className="form-check-input"
                type="checkbox"
                checked={isAbsolute}
                name="isAbsolute"
                onClick={(evt) => this.handleInputChange(evt)}
              />
              <Label for="isAbsolute" className="form-check-label">
                Enter absolute time
              </Label>
            </FormGroup>
            {timerWidget}
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button color="primary" disabled={this.isSubmitDisabled()}>
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default withRouter(TimerForm);
