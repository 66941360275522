import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Jumbotron } from "reactstrap";

import { loadPermissions } from "./Access";
import { rc2MainStore } from "./ReduxStore";
import ROUTES from "./utils/routes";

export default function LoadAuth({ children }) {
  const navigate = useNavigate();
  const [authStatus, setAuthStatus] = useState();
  useEffect(() => {
    loadPermissions(rc2MainStore).then((authed) => {
      if (!authed) {
        setAuthStatus(authed);
        navigate(ROUTES.LOGIN);
      } else {
        setAuthStatus(authed);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (authStatus === undefined) {
    return (
      <LoadingOverlay active={true} fadeSpeed={500}>
        <Container fluid>
          <Jumbotron>Loading</Jumbotron>
        </Container>
      </LoadingOverlay>
    );
  }

  return children || <Outlet />;
}
