import { faCheck, faScroll, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Button, ButtonGroup, UncontrolledTooltip } from "reactstrap";

import { config } from "./config";
import parrot from "./icons/gifs/parrot.gif";
import beacon from "./icons/svgs/beacon.svg";
import citadel from "./icons/svgs/citadel.svg";
import factory from "./icons/svgs/factory.svg";
import ihub from "./icons/svgs/ihub.svg";
import info from "./icons/svgs/info.svg";
import jammer from "./icons/svgs/jammer.svg";
import refinery from "./icons/svgs/refinery.svg";
import stargate from "./icons/svgs/stargate.svg";
import tcu from "./icons/svgs/tcu.svg";
import {
  countdownFormatter,
  dateFormatter,
  dotlanLinkFormatter,
} from "./sharedFormatters";
import { createViewTimerUrl } from "./utils/routes";
import withRouter from "./utils/withRouter";

function quickMark(id, state) {
  fetch(`${config.apiBase}/timers/${id}/update`, {
    method: "POST",
    body: JSON.stringify({
      finished_state: state,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      document.location.reload();
    } else {
      alert("Something went wrong");
    }
  });
}
class TimerDetails extends Component {
  render() {
    let { row } = this.props;
    let hotActions = "";
    if (new Date() > row.timer_countdown) {
      hotActions = (
        <>
          <Button
            size="sm"
            color="success"
            onClick={() => quickMark(row.id, "Saved")}
            id={"btn-saved-" + row.id}
          >
            <FontAwesomeIcon icon={faCheck} fixedWidth />
            <UncontrolledTooltip placement="top" target={"btn-saved-" + row.id}>
              Saved
            </UncontrolledTooltip>
          </Button>
          <Button
            size="sm"
            color="danger"
            onClick={() => quickMark(row.id, "Destroyed")}
            id={"btn-destroyed-" + row.id}
          >
            <FontAwesomeIcon icon={faTimes} fixedWidth />
            <UncontrolledTooltip
              placement="top"
              target={"btn-destroyed-" + row.id}
            >
              Destroyed
            </UncontrolledTooltip>
          </Button>
        </>
      );
    }
    return (
      <ButtonGroup>
        <Button
          onClick={() => this.props.router.navigate(createViewTimerUrl(row.id))}
          color="primary"
          size="sm"
          id={"btn-vd-" + row.id}
        >
          <FontAwesomeIcon icon={faScroll} fixedWidth />
          <UncontrolledTooltip placement="top" target={"btn-vd-" + row.id}>
            View Info
          </UncontrolledTooltip>
        </Button>
        {hotActions}
      </ButtonGroup>
    );
  }
}

const TimerDetailsWithRouter = withRouter(TimerDetails);

function detailsFormatter(_, row) {
  return <TimerDetailsWithRouter row={row} />;
}

const groupTypes = {
  1404: {
    altText: "Factory",
    icon: factory,
  },
  1406: {
    altText: "Refinery",
    icon: refinery,
  },
  1657: {
    altText: "Citadel",
    icon: citadel,
  },
};

const structureTypes = {
  35840: {
    altText: "Beacon",
    icon: beacon,
  },
  37534: {
    altText: "Jammer",
    icon: jammer,
  },
  32458: {
    altText: "IHub",
    icon: ihub,
  },
  35841: {
    altText: "Stargate",
    icon: stargate,
  },
  32226: {
    altText: "TCU",
    icon: tcu,
  },
};

const structureInfo = (type) => {
  if (type.group_id in groupTypes) {
    return groupTypes[type.group_id];
  }
  if (type.type_id in structureTypes) {
    return structureTypes[type.type_id];
  }
  return { icon: info, altText: "Other" };
};

function infoFormatter(_, row) {
  const popoverId = `popover-${row.id}`;
  const createdBy =
    "Created by: " +
    (row.created_by !== null ? row.created_by.username : "#System");

  const imgStyle = {
    margin: "6px",
  };

  const info = structureInfo(row.structure_type);
  return (
    <div className="text-center">
      <img
        id={popoverId}
        alt={info.altText}
        style={imgStyle}
        src={info.icon}
        height="30px"
      />
      <UncontrolledTooltip
        autohide={false}
        placement="right"
        target={popoverId}
        delay={{ show: 100, hide: 0 }}
      >
        <div className="linebreak-text">
          {row.notes}
          <br />
          {createdBy}
        </div>
      </UncontrolledTooltip>
    </div>
  );
}

const tickerFormatter = (_, row) => {
  return row.owning_alliance_ticker || row.owning_corp_ticker || "**None Set**";
};

function nameFormatter(data, row) {
  let style;
  switch (row.priority) {
    default:
    case 0:
      style = {};
      break;
    case 1:
      style = {
        fontWeight: "bold",
        color: "#ffff00",
      };
      break;
    case 2:
      style = {
        fontWeight: "bold",
        color: "#ff9900",
      };
      break;
    case 3:
      style = {
        fontWeight: "bold",
        color: "#ff0000",
      };
      break;
  }
  if (row.priority === 3) {
    const parrotImg = <img alt=":parrot:" title=":parrot:" src={parrot} />;
    return (
      <span style={style}>
        {parrotImg}
        {data}
        {parrotImg}
      </span>
    );
  } else {
    return <span style={style}>{data}</span>;
  }
}
export default (function (archived) {
  const info = {
    text: "Info",
    isDummyField: true,
    formatter: infoFormatter,
    dataField: "__info__",
    classes: (cell, row, rowIndex, colIndex) => {
      return row.is_hostile ? "red-cell" : "blue-cell";
    },
    headerStyle: () => {
      return { width: "50px", textAlign: "center" };
    },
  };
  const system = {
    text: "System",
    dataField: "solar_system",
    sort: true,
    formatter: dotlanLinkFormatter,
    filter: textFilter({
      placeholder: "System",
    }),
    filterValue: (v) => v.solar_system_name,
    headerStyle: () => {
      return { width: "100px" };
    },
  };
  const constellation = {
    text: "Const",
    dataField: "solar_system.constellation.constellation_name",
    sort: true,
    filter: textFilter({
      placeholder: "Const",
    }),
    headerStyle: () => {
      return { width: "100px" };
    },
  };

  const region = {
    text: "Region",
    dataField: "solar_system.region.region_name",
    sort: true,
    filter: textFilter({
      placeholder: "Region",
    }),
    headerStyle: () => {
      return { width: "100px" };
    },
  };
  const structureName = {
    text: "Structure Name",
    dataField: "structure_name",
    sort: true,
    filter: textFilter({
      placeholder: "Structure Name",
    }),
    formatter: nameFormatter,
  };
  const structureType = {
    text: "Type",
    dataField: "structure_type.type_name",
    sort: true,
    filter: textFilter({
      placeholder: "Type",
    }),
    headerStyle: () => {
      return { width: "100px" };
    },
  };
  const timerType = {
    text: "Timer",
    dataField: "timer_type",
    sort: true,
    filter: textFilter({
      placeholder: "Timer",
    }),
    headerStyle: () => {
      return { width: "80px" };
    },
  };
  const ownerTicker = {
    text: "Ticker",
    dataField: "owning_corp_ticker",
    sort: true,
    filter: textFilter({
      placeholder: "Ticker",
    }),
    filterValue: (_, row) => {
      return row.owning_alliance_ticker || row.owning_corp_ticker;
    },
    formatter: tickerFormatter,
    headerStyle: () => {
      return { width: "80px" };
    },
  };
  const expires = {
    text: "Expires",
    dataField: "timer_expires",
    formatter: dateFormatter,
    sort: true,
    headerStyle: () => {
      return { width: "160px" };
    },
  };
  const countdown = {
    text: "Countdown",
    dataField: "timer_countdown",
    sort: true,
    formatter: countdownFormatter,
    headerStyle: () => {
      return { width: "120px" };
    },
  };
  const priority = {
    text: "Priority",
    dataField: "priority",
    filter: textFilter({
      placeholder: "Priority",
    }),
    sort: true,
    headerStyle: () => {
      return { width: "90px" };
    },
  };
  const actions = {
    text: "Actions",
    isDummyField: true,
    formatter: detailsFormatter,
    dataField: "__actions__",
    headerStyle: () => {
      return { width: "110px" };
    },
  };
  const finishedState = {
    text: "Final State",
    dataField: "finished_state",
    sort: true,
  };
  if (archived) {
    return [
      info,
      system,
      constellation,
      region,
      structureName,
      structureType,
      timerType,
      ownerTicker,
      expires,
      finishedState,
      actions,
    ];
  } else {
    return [
      info,
      system,
      constellation,
      region,
      structureName,
      structureType,
      timerType,
      ownerTicker,
      expires,
      priority,
      countdown,
      actions,
    ];
  }
});
