import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { Col, Container, Jumbotron, Row } from "reactstrap";

import { config } from "./config";
import MenuList from "./MenuList";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export default class TaskLeaderboard extends Component {
  constructor(props) {
    super(props);
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    this.state = {
      year,
      month,
      data: [],
      options: [],
      isLoading: true,
      didError: false,
      oldYear: 0,
      oldMonth: 0,
    };
  }
  refreshData() {
    const { year, month, oldYear, oldMonth } = this.state;
    if (year === oldYear && oldMonth === month) {
      return;
    }
    this.setState({
      oldYear: year,
      oldMonth: month,
    });
    fetch(`${config.apiBase}/tasks/leaderboard/year/${year}/month/${month}`)
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const { data, columns } = this.filterData(json);
        this.setState({
          data,
          columns,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          didError: true,
        });
        console.log("Error: ", error);
      });
  }
  filterData(json) {
    const columns = [
      {
        text: "User",
        dataField: "username",
        sort: true,
        filter: textFilter(),
        headerStyle: () => {
          return { width: "200px" };
        },
      },
      {
        text: "Total",
        dataField: "#total",
        sort: true,
      },
    ];
    for (const col of json.task_types) {
      columns.push({
        text: col,
        dataField: col,
      });
    }
    const data = [];
    for (const user of Object.keys(json.leaderboard)) {
      const userRow = json.leaderboard[user];
      userRow["username"] = user;
      data.push(userRow);
    }
    return { columns, data };
  }

  handleSelector(key, val) {
    this.setState({
      [key]: val,
    });
  }

  renderTable() {
    this.refreshData();
    const { columns, data, isLoading, year, month } = this.state;
    let spp = localStorage.getItem("sizePerPage");
    if (spp === null) {
      spp = 10;
    } else {
      spp = Number(spp);
    }
    function onSPPC(spp, page) {
      localStorage.setItem("sizePerPage", spp);
    }
    const pagination = paginationFactory({
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "All", value: 9007199254740991 },
      ],
      showTotal: true,
      sizePerPage: spp,
      onSizePerPageChange: onSPPC,
    });
    const curYear = new Date().getFullYear();
    const yearOptions = [curYear, curYear - 1, curYear - 2];
    const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const table = (
      <Container fluid>
        <Row>
          <Col>
            <h1>Task Leaderboard</h1>
          </Col>
          <Col xs="2">
            <Select
              components={{ MenuList }}
              options={yearOptions}
              onChange={(val) => this.handleSelector("year", val)}
              getOptionValue={(x) => x}
              getOptionLabel={(x) => x}
              value={year}
            />
          </Col>
          <Col xs="2">
            <Select
              components={{ MenuList }}
              options={monthOptions}
              onChange={(val) => this.handleSelector("month", val)}
              getOptionValue={(x) => x}
              getOptionLabel={(x) => x}
              value={month}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              keyField={"header"}
              bootstrap4
              striped
              hover
              condensed
              data={data}
              columns={columns}
              filter={filterFactory()}
              pagination={pagination}
              classes={["overflow-table"]}
              defaultSorted={[
                {
                  dataField: "#total",
                  order: "desc",
                },
              ]}
              wrapperClasses={"overflow-table-wrapper"}
              fluid
            />
          </Col>
        </Row>
      </Container>
    );
    if (isLoading) {
      return "";
    } else {
      return table;
    }
  }
  render() {
    const { didError, isLoading } = this.state;
    const errorOutput = (
      <Jumbotron fluid={this.props.fluid} className="alert alert-danger">
        <Container fluid={this.props.fluid}>Error fetching content</Container>
      </Jumbotron>
    );

    let content;
    if (didError) {
      content = errorOutput;
    } else {
      content = this.renderTable();
    }

    return (
      <LoadingOverlay active={isLoading} fadeSpeed={500}>
        <Container fluid>{content}</Container>
      </LoadingOverlay>
    );
  }
}
