import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row } from "reactstrap";

import AsyncTable from "./AsyncTable.js";

class Quartermaster extends Component {
  render() {
    const columns = [
      {
        text: "Corporation",
        dataField: "corporation.name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "175px",
        },
      },
      {
        text: "Location",
        dataField: "description",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "300px",
        },
      },
      {
        text: "Amount",
        dataField: "amount",
        sort: true,
        formatter: (x) => x.toLocaleString(),
        classes: (_, row) => {
          if (row.amount * 2 < row.desired_amount) {
            return "text-danger";
          } else if (row.amount < row.desired_amount) {
            return "text-warning";
          } else {
            return "";
          }
        },
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Target Amount",
        dataField: "desired_amount",
        sort: true,
        formatter: (x) => x.toLocaleString(),
        headerStyle: {
          width: "100px",
        },
      },
    ];
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Quartermaster Report</h1>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"quartermaster_report"}
                columnDefinition={columns}
                keyField={"id"}
                sortKey={"id"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Quartermaster;
