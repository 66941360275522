import React, { Component } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import { PermissionRequired, Permissions } from "./Access";
import { config } from "./config";

class Management extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gateFuel: 0,
    };
  }
  autodetectPrivate() {
    fetch(`${config.apiBase}/management/autodetect_private`, {
      method: "POST",
    }).then((response) => {
      if (response.ok) {
        alert("Done");
      } else {
        alert("Something went wrong");
      }
    });
  }
  evictSessions() {
    fetch(`${config.apiBase}/management/evict_all_sessions`, {
      method: "POST",
    }).then((response) => {
      if (response.ok) {
        alert("Done");
      } else {
        alert(
          "Something went wrong. (Its probably fine, since you just got logged off too.)"
        );
      }
    });
  }
  gateFuelChange(evt) {
    this.setState({
      gateFuel: evt.target.value,
    });
  }
  submitGateFuel(evt) {
    fetch(
      `${config.apiBase}/stargates/all/fuel_level?value=${this.state.gateFuel}`,
      { method: "PUT" }
    ).then((response) => {
      if (response.ok) {
        alert("Done");
      } else {
        alert("Something went wrong");
      }
    });
  }
  render() {
    const { gateFuel } = this.state;
    return (
      <Container fluid>
        <PermissionRequired role={Permissions.EDIT_CITADEL_META}>
          <Row>
            <Col>
              <ButtonGroup className="float-left">
                <Button size="lg" onClick={() => this.autodetectPrivate()}>
                  Autodetect private citadels
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </PermissionRequired>
        <PermissionRequired role={Permissions.EDIT_ROLES}>
          <Row>
            <Col>
              <ButtonGroup className="float-left">
                <Button size="lg" onClick={() => this.evictSessions()}>
                  Kill all sessions
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </PermissionRequired>
        <PermissionRequired role={Permissions.SET_DESIRED_FUEL}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="gate_fuel">Gate Fuel Level</Label>
                <Input
                  id="gate_fuel"
                  onChange={(evt) => this.gateFuelChange(evt)}
                  value={gateFuel}
                  type="number"
                />
              </FormGroup>
              <ButtonGroup className="float-left">
                <Button size="lg" onClick={() => this.submitGateFuel()}>
                  Submit
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </PermissionRequired>
      </Container>
    );
  }
}

export default Management;
