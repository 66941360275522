import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row, Spinner } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import CitadelEditModal from "./CitadelEdit";

const citEdit = React.createRef();

function onEditCitadel(row) {
  return function (evt) {
    evt.preventDefault();
    citEdit.current.activate(row);
  };
}

const RENTAL_STATES_MAP = {
  NOT_RENTED: "Not Rented",
  ALTCORP: "AltCorp",
  MEMBERCORP: "Member Corp",
  ALLY: "Ally Corp",
  PUBLIC: "Public",
};

function addIndices(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].ix = i;
  }
  return data;
}

function tenantFormatter(cell) {
  if (cell.tenant) {
    return cell.tenant.name;
  }
  if (cell.tenant_id) {
    return "<" + cell.tenant_id + ">";
  }
  return "";
}

function rentalStateFormatter(cell) {
  return RENTAL_STATES_MAP[cell];
}

function nameFormatter(cell, row, ri) {
  return (
    <a href="#" onClick={onEditCitadel(row)}>
      {cell}
    </a>
  );
}

function powerStateFormatter(cell) {
  return cell ? "High" : "Low";
}

class CitadelRental extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const columns = [
      {
        text: "Name",
        dataField: "structure_name",
        sort: true,
        formatter: nameFormatter,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "100px",
        },
      },
      {
        text: "Const",
        dataField: "solar_system.constellation.constellation_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "80px",
        },
      },
      {
        text: "Type",
        dataField: "type.type_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "Rental State",
        dataField: "gsf_data.rental_state",
        sort: true,
        filter: textFilter(),
        formatter: rentalStateFormatter,
        filterValue: rentalStateFormatter,
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "Power",
        dataField: "fuel_expires",
        sort: true,
        formatter: powerStateFormatter,
        filterValue: powerStateFormatter,
        headerStyle: {
          width: "70px",
        },
      },
      {
        text: "Tenant",
        dataField: "gsf_data",
        filter: textFilter(),
        sort: true,
        formatter: tenantFormatter,
        filterValue: tenantFormatter,
        headerStyle: {
          width: "200px",
        },
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Moon Rentals</h1>
            </Col>
            <Col>{spinner}</Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"citadels/rental"}
                rowFilter={addIndices}
                columnDefinition={columns}
                keyField={"item_id"}
                sortKey={"structure_name"}
                auxFilter={(a) => this.auxFilter(a)}
                fluid
              />
            </Col>
          </Row>
        </Container>
        <CitadelEditModal ref={citEdit} parent={this} />
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
  auxFilter(data) {
    if (this.state.dataOverride) {
      data = this.state.dataOverride;
    } else {
      this.dataStash = data;
    }
    return data;
  }
}

export default CitadelRental;
