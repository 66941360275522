import React from "react";
import { sprintf } from "sprintf-js";

import { Countdown } from "./GlobalTime";

export function dotlanLink(solarSystem) {
  const region = solarSystem.region.region_name.replace(/ /g, "_");
  const system = solarSystem.solar_system_name.replace(/ /g, "_");
  return `https://evemaps.dotlan.net/map/${region}/${system}/`;
}

export function dotlanLinkFormatter(data) {
  const linky = dotlanLink(data);
  return (
    <a href={linky} target="_blank" rel="noopener noreferrer">
      {data.solar_system_name}
    </a>
  );
}

export function dateFormatter(cell) {
  return sprintf(
    "%d-%02d-%02d %02d:%02d:%02d",
    cell.getUTCFullYear(),
    cell.getUTCMonth() + 1,
    cell.getUTCDate(),
    cell.getUTCHours(),
    cell.getUTCMinutes(),
    cell.getUTCSeconds()
  );
}

export function countdownFormatter(cell) {
  return <Countdown date={cell} />;
}
