import "./index.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { RoleTable, TaskTypeTable, UserTable } from "./AccessControlTable";
import AddTimer from "./AddTimer";
import AssetsSearch from "./AssetSearch";
import BlueprintsWithRouter from "./Blueprints";
import CitadelRental from "./CitadelRental";
import Citadels from "./Citadels";
import Coreless from "./Coreless";
import EditTimer from "./EditTimer";
import FozzieSov from "./FozzieSov";
import FuelReport from "./FuelReport";
import { WithCurrentTime } from "./GlobalTime";
import IHubs from "./IHubs";
import InactiveMiners from "./InactiveMiners";
import Leaderboard from "./Leaderboard";
import { ListArchivedTimers, ListTimers } from "./ListTimers";
import ListTokens from "./ListTokens";
import LoadAuth from "./LoadAuth";
import Login from "./Login";
import Management from "./Management";
import MoonExtractions from "./MoonExtractions";
import NavBar from "./NavBar";
import NotificationsReport from "./Notifications";
import Pos from "./Pos";
import Quartermaster from "./Quartermaster";
import { rc2MainStore } from "./ReduxStore";
import Stargates from "./Stargates";
import { ActiveTasks, ArchivedTasks } from "./Tasks";
import TokenSubmitSuccess from "./TokenSubmitSuccess";
import Unanchoring from "./Unanchoring";
import CharacterSelector from "./UserSettings";
import ROUTES from "./utils/routes";
import ViewTimer from "./ViewTimer";

function RC2Main() {
  return (
    <WithCurrentTime>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route element={<LoadAuth />}>
            <Route path={ROUTES.TIMERS} element={<ListTimers />} />
            <Route
              path={ROUTES.ARCHIVED_TIMERS}
              element={<ListArchivedTimers />}
            />
            <Route path={ROUTES.FOZZIESOV_TIMERS} element={<FozzieSov />} />
            <Route path={ROUTES.ADD_TIMER} element={<AddTimer />} />
            <Route path={ROUTES.VIEW_TIMER} element={<ViewTimer />} />
            <Route path={ROUTES.EDIT_TIMER} element={<EditTimer />} />
            <Route path={ROUTES.TASKS_ACTIVE} element={<ActiveTasks />} />
            <Route path={ROUTES.TASKS_ARCHIVED} element={<ArchivedTasks />} />
            <Route path={ROUTES.TASKS_LEADERBOARD} element={<Leaderboard />} />
            <Route path={ROUTES.GSOL_CITADELS} element={<Citadels />} />
            <Route
              path={ROUTES.GSOL_MOON_RENTALS}
              element={<CitadelRental />}
            />
            <Route
              path={ROUTES.GSOL_MOON_FRACKS}
              element={<MoonExtractions />}
            />
            <Route path={ROUTES.GSOL_STARGATES} element={<Stargates />} />
            <Route path={ROUTES.GSOL_IHUBS} element={<IHubs />} />
            <Route path={ROUTES.GSOL_UNANCHORING} element={<Unanchoring />} />
            <Route path={ROUTES.GSOL_POS} element={<Pos />} />
            <Route path={ROUTES.REPORTS_FUEL} element={<FuelReport />} />
            <Route
              path={ROUTES.REPORTS_INACTIVE_MINERS}
              element={<InactiveMiners />}
            />
            <Route
              path={ROUTES.REPORTS_SEARCH_ASSETS}
              element={<AssetsSearch />}
            />
            <Route
              path={ROUTES.REPORTS_NOTIFICATIONS}
              element={<NotificationsReport />}
            />
            <Route
              path={ROUTES.REPORTS_QUARTERMASTER}
              element={<Quartermaster />}
            />
            <Route
              path={ROUTES.REPORTS_BLUEPRINTS_STRUCTURE}
              element={
                <BlueprintsWithRouter
                  marketgroup_id={"1913"}
                  title={"Structure Components"}
                />
              }
            />
            <Route
              path={ROUTES.REPORTS_BLUEPRINTS_SOVINFRA}
              element={
                <BlueprintsWithRouter
                  marketgroup_id={"2015"}
                  title={"Sovereignty and Infrastructure"}
                />
              }
            />
            <Route path={ROUTES.REPORTS_CORELESS} element={<Coreless />} />
            <Route path={ROUTES.ADMIN_USERS} element={<UserTable />} />
            <Route path={ROUTES.ADMIN_ROLES} element={<RoleTable />} />
            <Route path={ROUTES.ADMIN_TASKS} element={<TaskTypeTable />} />
            <Route path={ROUTES.ADMIN_TOOLS} element={<Management />} />
            <Route path={ROUTES.ADMIN_TOKENS} element={<ListTokens />} />
            <Route
              path={ROUTES.USER_CHARACTERS}
              element={<CharacterSelector />}
            />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route
              path={ROUTES.TOKEN_SUBMIT_SUCCESS}
              element={<TokenSubmitSuccess />}
            />
            <Route path="*" element={<Navigate to={ROUTES.TIMERS} replace />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </WithCurrentTime>
  );
}

ReactDOM.render(
  <Provider store={rc2MainStore}>
    <RC2Main />
  </Provider>,
  document.getElementById("root")
);
