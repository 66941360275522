import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  DropdownItem as BSDropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav as BootstrapNav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from "reactstrap";

import { PermissionRequired } from "./Access";
import { NavClock } from "./GlobalTime";
import commandBee from "./icons/pngs/commandbee.png";
import ROUTES, { SITEMAP } from "./utils/routes";

function DropdownItem({ name, link, role }) {
  const render = (
    <Link to={link}>
      <BSDropdownItem>{name}</BSDropdownItem>
    </Link>
  );
  if (Array.isArray(role)) {
    return <PermissionRequired anyOf={role}>{render}</PermissionRequired>;
  } else if (role !== undefined) {
    return <PermissionRequired role={role}>{render}</PermissionRequired>;
  } else {
    return render;
  }
}

function Dropdown({ name, links, hrefLink, role }) {
  let permissions = new Set();
  let render = null;
  if (links) {
    links.forEach((link) => {
      if (!("role" in link)) {
        // If any link should be visible to all users, disable permission check on dropdown
        permissions = null;
      } else if (permissions !== null) {
        if (Array.isArray(link.role)) {
          link.role.forEach((item) => {
            permissions.add(item);
          });
        } else {
          permissions.add(link.role);
        }
      }
    });
    render = (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {name}
        </DropdownToggle>
        <DropdownMenu right>
          {links.map((linkProps) => (
            <DropdownItem key={linkProps.link} {...linkProps} />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  } else if (hrefLink) {
    if (!role) {
      permissions = null;
    } else {
      permissions.add(role);
    }
    render = (
      <NavItem>
        <NavLink href={hrefLink}>{name}</NavLink>
      </NavItem>
    );
  }
  if (permissions !== null) {
    return (
      <PermissionRequired anyOf={[...permissions]}>{render}</PermissionRequired>
    );
  } else {
    return render;
  }
}

export default function NavBar() {
  return (
    <Navbar color="primary" dark expand>
      <Container>
        <Link className="navbar-brand" to={ROUTES.TIMERS}>
          <img alt="Region Commander Bee" src={commandBee} width="50px" />
          Region Commander 2
        </Link>
        <BootstrapNav navbar className="mr-auto">
          {SITEMAP.map((dropdownProps) => (
            <Dropdown key={dropdownProps.name} {...dropdownProps} />
          ))}
        </BootstrapNav>
        <NavClock />
      </Container>
    </Navbar>
  );
}
