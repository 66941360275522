import datetimeDifference from "datetime-difference";
import React, { Component } from "react";
import { sprintf } from "sprintf-js";

const TimeContext = React.createContext(new Date());

export class WithCurrentTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curTime: new Date(),
    };
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        curTime: new Date(),
      });
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const { children } = this.props;
    const { curTime } = this.state;
    return (
      <TimeContext.Provider value={curTime}>{children}</TimeContext.Provider>
    );
  }
}

export class Countdown extends Component {
  static contextType = TimeContext;
  render() {
    const curTime = this.context;
    const { date } = this.props;
    return this.calculateCountdown(date, curTime);
  }
  calculateCountdown(endDate, now) {
    if (now > endDate) {
      return "EXPIRED";
    }

    const diff = datetimeDifference(now, endDate);

    let timeStr = "";
    if (diff.years > 0) {
      timeStr += `${diff.years}y `;
    }
    if (diff.months > 0) {
      timeStr += `${diff.months}mo `;
    }
    if (diff.days > 0) {
      timeStr += `${diff.days}d `;
    }
    timeStr += sprintf(
      "%02d:%02d:%02d",
      diff.hours,
      diff.minutes,
      diff.seconds
    );

    return timeStr;
  }
}

Countdown.defaultProps = {
  date: new Date(),
};

export class NavClock extends Component {
  static contextType = TimeContext;
  padNumber(number) {
    const s = number.toString();
    return s.padStart(2, "0");
  }

  render() {
    const curTime = this.context;
    return (
      <div>
        <span className="navbar-text">
          Local Time: {this.padNumber(curTime.getHours())}:
          {this.padNumber(curTime.getMinutes())}
        </span>
        <span className="navbar-text">
          Eve Time: {this.padNumber(curTime.getUTCHours())}:
          {this.padNumber(curTime.getUTCMinutes())}
        </span>
      </div>
    );
  }
}
