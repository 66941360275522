import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";

import { config } from "./config";

function CharacterSelector({ apiBase, initialPilotId }) {
  const [charactersData, setCharactersData] = useState({
    pilots: [],
    initialpilotid: null,
    initialpilotname: null,
  });
  const [selectedCharacterId, setSelectedCharacterId] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Fetch characters from the ${config.apiBase}/user/characters endpoint
    fetch(`${config.apiBase}/user/characters`)
      .then((response) => response.json())
      .then((data) => setCharactersData(data))
      .catch((error) => console.error(error));
  }, [apiBase]);

  const { pilots, initialpilotid, initialpilotname } = charactersData;

  useEffect(() => {
    if (initialPilotId) {
      setSelectedCharacterId(initialPilotId);
    }
  }, [initialPilotId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCharacterId) {
      setMessage("Please select a character");
      return;
    }

    try {
      // Send a POST request to the ${config.apiBase}/user/characters endpoint
      const response = await fetch(`${config.apiBase}/user/characters`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ characterId: selectedCharacterId }),
      });

      if (response.ok) {
        setMessage(
          `Character with ID "${selectedCharacterId}" selected successfully.`
        );
      } else {
        setMessage("Error selecting character.");
      }
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while selecting the character.");
    }
  };

  return (
    <div>
      <h1>Character Selector</h1>
      {initialpilotname && (
        <p>Current Character Selected: {initialpilotname}</p>
      )}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="characterSelect">Select a character:</label>
          <select
            id="characterSelect"
            name="character"
            value={selectedCharacterId}
            onChange={(e) => setSelectedCharacterId(e.target.value)}
          >
            <option value="">Select a character</option>
            {pilots.map((character) => (
              <option key={character.id} value={character.id}>
                {character.name}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Submit</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default CharacterSelector;
