import React, { Component } from "react";
import Select from "react-select";

import { config } from "./config";
import MenuList from "./MenuList";

export default class SolarSystemSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSystem: props.value,
      id: props.id || "systemSelect",
      onChange: props.onChange,
    };
  }
  componentDidMount() {
    fetch(`${config.apiBase}/systems.json`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          systems: data,
        });
      });
  }
  systemSelected = (val) => {
    this.setState({
      selectedSystem: val,
    });
    if (this.state.onChange) {
      this.state.onChange(val);
    }
  };
  prefixOnlyFilter(item, currentText) {
    const { label } = item;
    const lowercaseLabel = label.toString().toLowerCase();
    return lowercaseLabel.startsWith(currentText.toLowerCase());
  }
  render() {
    const { id, systems, selectedSystem, required } = this.state;
    return (
      <Select
        components={{ MenuList }}
        id={id}
        options={systems}
        onChange={this.systemSelected}
        filterOption={this.prefixOnlyFilter}
        getOptionValue={(option) => option["solar_system_id"]}
        // TODO it would be really cool if we could render the region name next to this
        getOptionLabel={(option) => option["solar_system_name"]}
        value={selectedSystem}
        required={required}
      />
    );
  }
}
