import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import Select from "react-select";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { config } from "./config";
import MenuList from "./MenuList";

function pathFormatter(path) {
  let pathParts = [];
  for (const pathPart of path) {
    const isCorpHangar = pathPart.loc_flag.match(/CorpSAG(.)/);
    const isContainer = pathPart.loc_flag.match(/(?:Unlocked)|(?:Locked)/);
    if (
      pathPart.loc_flag === "AutoFit" ||
      pathPart.loc_flag === "OfficeFolder"
    ) {
      pathParts.push(pathPart.name);
    } else if (isCorpHangar) {
      pathParts.push(pathPart.name + " (SAG" + isCorpHangar[1] + ")");
    } else if (isContainer) {
      pathParts.push(pathPart.name + " (" + pathPart.loc_flag + ")");
    } else if ("name" in pathPart) {
      pathParts.push(pathPart.loc_flag + " of " + pathPart.name);
    } else {
      pathParts.push(pathPart.loc_flag + " of " + pathPart.type);
    }
  }
  return pathParts.join(" > ");
}

function numberFormatter(amount) {
  return amount.toLocaleString();
}

class AssetsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: "",
      valid: false,
      searchForCopy: "",
      searchableCorps: [],
      activeCorp: null,
    };
  }
  componentDidMount() {
    fetch(`${config.apiBase}/searchable_corps`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          searchableCorps: data,
        });
      });
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.value;
    this.setState({
      searchFor: value,
    });
  }
  submit(evt) {
    evt.preventDefault();
    const { searchFor } = this.state;
    this.setState({
      searchForCopy: searchFor,
      valid: searchFor.length >= 3,
    });
  }
  handleSelector(val) {
    this.setState({
      activeCorp: val,
    });
  }
  render() {
    const { searchFor, valid, searchForCopy, searchableCorps, activeCorp } =
      this.state;
    const columns = [
      {
        text: "Path",
        dataField: "path",
        sort: true,
        filter: textFilter(),
        filterValue: pathFormatter,
        formatter: pathFormatter,
      },
      {
        text: "Amount",
        dataField: "amount",
        sort: true,
        headerStyle: {
          width: "100px",
        },
        formatter: numberFormatter,
      },
      {
        text: "Type",
        dataField: "type",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "200px",
        },
      },
    ];
    let table = "";
    if (valid) {
      table = (
        <AsyncTable
          requestUrl={
            "search_assets?corp_id=" +
            activeCorp.id +
            "&type=" +
            encodeURIComponent(searchForCopy)
          }
          columnDefinition={columns}
          keyField={"item_id"}
          sortKey={"item_id"}
          fluid
        />
      );
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col xs="2" />
            <Col xs="8">
              <Form onSubmit={(evt) => this.submit(evt)}>
                <FormGroup>
                  <Input
                    className={"col-8"}
                    style={{ display: "inline-block" }}
                    placeholder={"Type Name"}
                    onChange={(evt) => this.handleInputChange(evt)}
                    value={searchFor}
                  />
                  <div className="col-3" style={{ display: "inline-block" }}>
                    <Select
                      components={{ MenuList }}
                      options={searchableCorps}
                      onChange={(val) => this.handleSelector(val)}
                      getOptionValue={(option) => option["id"]}
                      getOptionLabel={(options) => options["name"]}
                      value={activeCorp}
                    />
                  </div>
                  <Button color="primary" className={"col-1"}>
                    Search
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>{table}</Row>
        </Container>
      </div>
    );
  }
}

export default AssetsSearch;
