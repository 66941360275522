import update from "immutability-helper";
import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { config } from "./config";
import { Countdown } from "./GlobalTime";
import { dateFormatter } from "./sharedFormatters";

function claimTask(self, row) {
  return function (evt) {
    self.setState({
      hasInflight: true,
    });
    fetch(`${config.apiBase}/tasks/moon_frack`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: row.citadel.item_id,
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          self.setState({
            dataOverride: update(self.getDS(), {
              [row.ix]: {
                tasked_to: {
                  $set: "You",
                },
              },
            }),
          });
        } else {
          alert("Something went wrong.");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
      })
      .finally((_) => {
        self.setState({
          hasInflight: false,
        });
      });
  };
}

function actionsFormatter(self, cell, row) {
  if (row.tasked_to !== null) {
    return `Assigned to: ${row.tasked_to}`;
  }
  if ((row.arrival_time_dt - new Date()) / 1000 > 3600) {
    return "";
  }
  return (
    <div>
      <Button color={"primary"} size={"sm"} onClick={claimTask(self, row)}>
        Claim Task
      </Button>
    </div>
  );
}

function expiryFormatter(cell) {
  if (cell !== null) {
    return <Countdown date={cell} />;
  }
  return "";
}

function parseDates(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].arrival_time_dt = new Date(data[i].arrival_time);
    data[i].autofrack_time_dt = new Date(data[i].autofrack_time);
    data[i].ix = i;
  }
  return data;
}

class MoonExtractions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHidden: false,
    };
  }
  handleInputChange(evt) {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    const spacerStyle = {
      marginTop: "10px",
      marginBottom: "10px",
      marginRight: "20px",
    };
    const actFmt = (cell, row) => actionsFormatter(this, cell, row);
    const columns = [
      {
        text: "Name",
        dataField: "citadel.structure_name",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Region",
        dataField: "citadel.solar_system.region.region_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "80px",
        },
      },
      {
        text: "Moon",
        dataField: "moon.item_name",
        sort: true,
        filter: textFilter(),
        headerStyle: {
          width: "170px",
        },
      },
      {
        text: "Arrival time",
        dataField: "arrival_time",
        sort: true,
        formatter: (x) => dateFormatter(new Date(x)),
      },
      {
        text: "Arrival countdown",
        dataField: "arrival_time_dt",
        sort: true,
        formatter: expiryFormatter,
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "Autofrack time",
        dataField: "autofrack_time",
        sort: true,
        formatter: (x) => dateFormatter(new Date(x)),
      },
      {
        text: "Autofrack countdown",
        dataField: "autofrack_time_dt",
        sort: true,
        formatter: expiryFormatter,
        headerStyle: {
          width: "150px",
        },
      },
      {
        text: "",
        dataField: "123",
        isDummyField: true,
        formatter: actFmt,
        headerStyle: {
          width: "90px",
        },
        style: {
          textAlign: "center",
        },
      },
    ];
    let spinner = "";
    if (this.state.hasInflight) {
      spinner = <Spinner color="primary" className="float-right" />;
    }
    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Moon Fracks</h1>
            </Col>
            <Col>
              <Form className="float-right">
                <FormGroup check style={spacerStyle}>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="showHidden"
                      id="inputShowHiddenCits"
                      checked={this.state.showHidden}
                      onChange={(e) => this.handleInputChange(e)}
                    />{" "}
                    Show Hidden Fracks
                  </Label>
                </FormGroup>
              </Form>
              {spinner}
            </Col>
          </Row>
          <AsyncTable
            requestUrl={"moon_extractions"}
            rowFilter={parseDates}
            columnDefinition={columns}
            keyField={"citadel_id"}
            sortKey={"arrival_time"}
            auxFilter={(a) => this.auxFilter(a)}
            fluid
          />
        </Container>
      </div>
    );
  }
  getDS() {
    if (this.state.dataOverride) {
      return this.state.dataOverride;
    } else {
      return this.dataStash;
    }
  }
  auxFilter(data) {
    if (this.state.dataOverride) {
      data = this.state.dataOverride;
    } else {
      this.dataStash = data;
    }
    if (this.state.showHidden) {
      return data;
    } else {
      return data.filter((a) => {
        if (a.citadel.gsf_data.hidden) {
          return false;
        }
        if (a.citadel.last_fracked_at === null) {
          return true;
        }
        return (
          new Date(Date.parse(a.citadel.last_fracked_at) + 14400000) <
          new Date()
        );
      });
    }
  }
}

export default MoonExtractions;
